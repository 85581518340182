import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { AppContext } from '../context/AppContext'

import Icon from '../icons/Icon'
import Tooltip from '../pageElements/Tooltip'

import styled from 'styled-components'
import VehicleConditionCard from '@common/pageElements/VehicleConditionCard'
import RadioButtonCard from '@common/pageElements/RadioButtonCard'

const StyledRadioButton = styled.div`
  text-align: left;
  .tradein-rb-container {
    border: 0;
    margin: 0;
    padding: 0;
    color: var(--moderateBlack);
    legend {
      margin-bottom: 16px;
      line-height: 24px;
    }
    label {
      display: flex;
      align-items: center;
      color: var(--primary);
    }
    label.tradein-ford-rb {
      margin-bottom: 16px;
      position: relative;
      span {
        font-size: 14px;
        line-height: 20px;
      }
    }
    &.ford {
    }

    #overallCondition-radio-group + .tradein-rb-container {
      flex-direction: column;
    }

    .tradein-card-container {
      display: flex;
      flex-wrap: wrap;
    }

    .tradein-error-message {
      font-family: var(--fontPrimary);
      padding: 0;
      margin-top: 25px;
      font-size: var(--fz-xxs);
    }
  }
`

const StyledTooltip = styled(Tooltip)`
  margin-left: 10px;
`

const RadioButton = ({
  analyticsEvent,
  ariaLabels,
  index,
  label,
  id,
  options,
  value,
  onChange,
  showErrors,
  tooltip,
  tooltipAriaLabel,
  text,
  style,
  errorMessage,
  initialValue,
  isUS,
  isoffline,
  subtitle,
  directionStyle,
  className,
  selectText
}) => {
  function setChecked(ans) {
    if (ans !== null && isUS && index === 0) {
      return initialValue === ans.toUpperCase()
    }
    return ans === initialValue || false
  }

  const { brand } = useContext(AppContext) || ''

  const radioBtnClass = className ? `${className} ${brand}` : brand
  const fordUS = brand === 'ford' ? true : false

  return (
    <StyledRadioButton
      style={style}
      role='radiogroup'
      aria-label={id}
      aria-labelledby={id}
      className={radioBtnClass}
    >
      <fieldset className={`tradein-rb-container`}>
        <legend id={id}>
          {label}
          {tooltip && (
            <StyledTooltip
              tooltipBody={tooltip}
              analyticsEvent={analyticsEvent}
              id={id}
              ariaLabel={tooltipAriaLabel}
            />
          )}
        </legend>
        <div className={`tradein-card-wrapper`}>
          {options.map((answer, i) =>
            (isUS && index === 0) || isoffline ? (
              brand === 'ford' ? (
                <RadioButtonCard
                  name={answer}
                  subtitle={subtitle ? subtitle[i] : ''}
                  id={i}
                  index={i}
                  text={text[i]}
                  onChange={onChange}
                  setChecked={setChecked}
                  value={value}
                  isCondition
                />
              ) : (
                <VehicleConditionCard
                  answer={answer.toUpperCase()}
                  subtitle={isUS ? subtitle[i] : ''}
                  texts={text[i]}
                  onChange={onChange}
                  setChecked={setChecked}
                  name={label}
                  aria-label={ariaLabels[i]}
                  id={id + i}
                  index={i}
                  selectText={selectText}
                  showErrors={showErrors}
                  value={value ? value.toUpperCase() : null}
                  isoffline={isoffline}
                />
              )
            ) : (
              <label
                showErrors={showErrors}
                key={label + i}
                className='tradein-ford-rb'
              >
                <input
                  onChange={onChange}
                  id={id + i}
                  type='radio'
                  name={label}
                  aria-label={ariaLabels[i]}
                  value={answer}
                  aria-describedby={'error-' + label}
                  defaultChecked={setChecked(answer)}
                  className={`${!value && showErrors ? 'error ' : ''}`}
                />
                <span htmlFor={id + i}>
                  {answer}
                  {text && <span> {text[i]}</span>}
                </span>
              </label>
            )
          )}
        </div>
      </fieldset>
      {showErrors && !value && !fordUS && (
        <div
          className='tradein-error-message'
          id={'error-' + label}
          aria-live='assertive'
          role='alert'
        >
          <Icon icon={brand === 'ford' ? 'error' : 'lincolnError'} />
          {errorMessage}
        </div>
      )}
    </StyledRadioButton>
  )
}

RadioButton.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  ariaLabels: PropTypes.array,
  errorMessage: PropTypes.string
}

RadioButton.defaultProps = {
  label: 'Is this a radio button question?',
  options: ['Yes', 'No', 'Maybe'],
  ariaLabels: ['', '', '', '', '', ''],
  errorMessage: 'This answer is required to move forward'
}

export default RadioButton
