import api from '../utils/api'

export const getTrimList = (options) => {
  var data = []
  for (var i = 0; i < options.length; i++) {
    var obj = { name: options[i], label: options[i], value: options[i] }
    data.push(obj)
  }
  return data
}

export const getAnalyticsUrl = (brand, hostName) => {
  const script = document.createElement('script')
  const source =
    brand === 'ford'
      ? hostName.includes('dev') ||
        hostName.includes('qa') ||
        hostName.includes('localhost')
        ? 'https://www.ford.com/adobedtmredirect/248d63c2ff6b/5e8baaa76508/launch-0b65ecd247be-staging.min'
        : 'https://www.ford.com/adobedtmredirect/248d63c2ff6b/5e8baaa76508/launch-dda3f8250d58.min'
      : hostName.includes('dev') ||
        hostName.includes('qa') ||
        hostName.includes('localhost')
      ? 'https://www.lincoln.com/adobedtmredirect/248d63c2ff6b/72830f119bfd/launch-26ee62ffec1b-staging.min'
      : 'https://www.lincoln.com/adobedtmredirect/248d63c2ff6b/72830f119bfd/launch-7f98000aca97.min'
  script.src = source
  script.type = 'text/javascript'
  document.body.appendChild(script)
}

export const oneTrustBanner = (brand) => {
  const domainScript =
    brand === 'lincoln'
      ? 'e68b490a-bbfa-4cf0-a492-de3c092da1df'
      : '474f6f1e-8e77-40c9-97fd-ab58b6e08de0'

  // OneTrust cookie banner
  const script = document.createElement('script')
  script.setAttribute('data-domain-script', domainScript)
  script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
  script.type = 'text/javascript'

  document.body.appendChild(script)
  return () => {
    document.body.removeChild(script)
  }

  // End of OneTrust cookie banner
}

// place the appraisal id before the '#'
// if query parameter ('?') is already present append with '&'
export const buildShowroomUrl = (url, appraisalId) => {
  const hashIndex = url.lastIndexOf('#')
  if (hashIndex > -1) {
    const opr = url.includes('?') ? '&' : '?'
    return (
      url.substring(0, hashIndex) +
      opr +
      'appraisalId=' +
      appraisalId +
      url.substring(hashIndex, url.length)
    )
  } else {
    const opr = url.includes('?') ? '&' : '?'
    return url + opr + 'appraisalId=' + appraisalId
  }
}

export const sanitizeUrl = (urlString) => {
  function isValidUrl(urlString) {
    try {
      const testUrl =
        urlString.startsWith('http://') || urlString.startsWith('https://')
          ? new URL(urlString)
          : new URL('https://' + urlString)

      // Ensure the URL has a valid domain
      const hostname = testUrl.hostname
      return (
        hostname.includes('.') &&
        hostname.split('.').every((part) => part.length > 0)
      )
    } catch (error) {
      return false
    }
  }

  if (isValidUrl(urlString)) {
    // Ensure the URL starts with "https://"
    if (!urlString.startsWith('https://')) {
      return 'https://' + urlString.replace(/^(http:\/\/|www\.)/, '')
    }
    return urlString // Already valid and starts with "https://"
  } else {
    return 'https://www.ford.com' // Return Ford.com if invalid
  }
}
