const fordTag = 'fv'
const lincolnTag = 'ln'
const tag = window.location.hostname.includes('lincoln') ? lincolnTag : fordTag
const client = window.location.hostname.includes('lincoln')
  ? 'lincoln'
  : 'ford-us'
const site = window.location.hostname.includes('lincoln')
  ? 'lincoln.com'
  : 'fordvehicles.com'

function pageLoad(digitaldata, trackingString) {
  try {
    window.digitaldata.event = []
    window.digitaldata = digitaldata
    if (digitaldata.page.variantName) {
      window._satellite.track(digitaldata.page.variantName)
    } else {
      window._satellite.track(trackingString)
    }
  } catch (err) {
    console.log('Analytics blocked', err)
  }
}

export default {
  ownershipStatus: () => {
    pageLoad({
      page: {
        pageName: `${tag}:trd:cart:trade in:ownership status`,
        siteSection: 'cart',
        hierarchy: 'shopping tools:trd:trade in',
        userLanguage: 'eng',
        pageNameNoVehicle: `${tag}:trd:cart:trade in:ownership status`,
        client: client,
        site: site,
        radUIVersion: window.radUIVersion,
        variantName: 'trd-trade-in-vehicle-info'
      }
    })
  },
  selectVehicle: (toolDescriptor, year, make, model) => {
    pageLoad({
      page: {
        pageName: `${tag}:trd:cart:trade in:select vehicle`,
        siteSection: 'cart',
        hierarchy: `shopping tools:trd:trade in`,
        userLanguage: 'eng',
        pageNameNoVehicle: `${tag}:trd:cart:trade in:select vehicle`,
        client: client,
        site: site,
        tool: 'trd:vehicle info type',
        toolDescriptor: `trd:${toolDescriptor}`,
        radUIVersion: window.radUIVersion,
        variantName: 'trd-trade-in-vehicle-select'
      },
      vehicle: {
        modelYear: `${year}`,
        nameplate: `${model}`,
        currentMake: `${make}`
      }
    })
  },
  vinOrPlate: () => {
    pageLoad({
      page: {
        pageName: `${tag}:trd:cart:trade in:vehicle info`,
        siteSection: 'cart',
        hierarchy: 'shopping tools:trd:trade in',
        userLanguage: 'eng',
        pageNameNoVehicle: `${tag}:trd:cart:trade in:vehicle info`,
        client: client,
        site: site,
        radUIVersion: window.radUIVersion,
        variantName: 'trd-trade-in-start'
      }
    })
  },
  enterFinanceInfo: () => {
    pageLoad({
      page: {
        pageName: `${tag}:trd:cart:trade in:vehicle:financing info:account:1a`,
        siteSection: 'cart',
        hierarchy: 'shopping tools:trd:trade in',
        userLanguage: 'eng',
        pageNameNoVehicle: `${tag}:trd:cart:trade in:vehicle:financing info:account:1a`,
        client: client,
        site: site,
        radUIVersion: window.radUIVersion,
        variantName: 'trd-trade-in-vehicle-info'
      }
    })
  },
  vehicleBasics: () => {
    pageLoad({
      page: {
        pageName: `${tag}:trd:cart:trade in:vehicle:basics`,
        siteSection: 'cart',
        hierarchy: 'shopping tools:trd:trade in',
        userLanguage: 'eng',
        pageNameNoVehicle: `${tag}:trd:cart:trade in:vehicle:basics`,
        client: client,
        site: site,
        radUIVersion: window.radUIVersion,
        variantName: 'trd-trade-in-vehicle-info'
      }
    })
  },
  enterFinanceInfoManual: () => {
    pageLoad({
      page: {
        pageName: `${tag}:trd:cart:trade in:vehicle:financing info:manual:1b`,
        siteSection: 'cart',
        hierarchy: 'shopping tools:trd:trade in',
        userLanguage: 'eng',
        pageNameNoVehicle: `${tag}:trd:cart:trade in:vehicle:financing info:manual:1b`,
        client: client,
        site: site,
        radUIVersion: window.radUIVersion,
        variantName: 'trd-trade-in-vehicle-info'
      }
    })
  },
  balanceFinanceInfoIncorrect: () => {
    pageLoad({
      page: {
        pageName: `${tag}:trd:cart:trade in:vehicle:remaining balance info:incorrect`,
        siteSection: 'cart',
        hierarchy: 'shopping tools:trd:trade in',
        userLanguage: 'eng',
        pageNameNoVehicle: `${tag}:trd:cart:trade in:vehicle:remaining balance info:incorrect`,
        client: client,
        site: site,
        radUIVersion: window.radUIVersion,
        variantName: 'trd-trade-in-vehicle-info'
      }
    })
  },
  enterLeaseInfo: () => {
    pageLoad({
      page: {
        pageName: `${tag}:trd:cart:trade in:vehicle:leasing info:account:1a`,
        siteSection: 'cart',
        hierarchy: 'shopping tools:trd:trade in',
        userLanguage: 'eng',
        pageNameNoVehicle: `${tag}:trd:cart:trade in:vehicle:leasing info:account:1a`,
        client: client,
        site: site,
        radUIVersion: window.radUIVersion,
        variantName: 'trd-trade-in-vehicle-info'
      }
    })
  },
  balanceLeaseInfoIncorrect: () => {
    pageLoad({
      page: {
        pageName: `${tag}:trd:cart:trade in:vehicle:leasing obligation info:incorrect`,
        siteSection: 'cart',
        hierarchy: 'shopping tools:trd:trade in',
        userLanguage: 'eng',
        pageNameNoVehicle: `${tag}:trd:cart:trade in:vehicle:leasing obligation info:incorrect`,
        client: client,
        site: site,
        radUIVersion: window.radUIVersion,
        variantName: 'trd-trade-in-vehicle-info'
      }
    })
  },
  tradeInAppraisal: () => {
    pageLoad({
      page: {
        pageName: `${tag}:trd:cart:trade in:appraisal`,
        siteSection: 'cart',
        hierarchy: 'shopping tools:trd:trade in',
        userLanguage: 'eng',
        pageNameNoVehicle: `${tag}:trd:cart:trade in:appraisal`,
        client: client,
        site: site,
        tool: 'event:trade-in',
        radUIVersion: window.radUIVersion,
        variantName: 'trd-trade-in-appraisals'
      }
    })
  },
  enterLeaseInfoManual: () => {
    pageLoad({
      page: {
        pageName: `${tag}:trd:cart:trade in:vehicle:leasing info:manual:1b`,
        siteSection: 'cart',
        hierarchy: 'shopping tools:trd:trade in',
        userLanguage: 'eng',
        pageNameNoVehicle: `${tag}:trd:cart:trade in:vehicle:leasing info:manual:1b`,
        client: client,
        site: site,
        radUIVersion: window.radUIVersion,
        variantName: 'trd-trade-in-vehicle-info'
      }
    })
  },
  vehicleCondition: () => {
    pageLoad({
      page: {
        pageName: `${tag}:trd:cart:trade in:vehicle:condition`,
        siteSection: 'cart',
        hierarchy: 'shopping tools:trd:trade in',
        userLanguage: 'eng',
        pageNameNoVehicle: `${tag}:trd:cart:trade in:vehicle:condition`,
        client: client,
        site: site,
        radUIVersion: window.radUIVersion,
        variantName: 'trd-trade-in-vehicle-info'
      }
    })
  },
  /*
  vehicleSummary: () => {
    pageLoad({
      page: {
        pageName: 'fv:trd:cart:trade in:vehicle:summary',
        siteSection: 'cart',
        hierarchy: `trd:trade in:vehicle summary`,
        userLanguage: 'eng',
        pageNameNoVehicle: `fv:trd:trade in:vehicle:summary`,
        client: client,
        site: site,
        radUIVersion: window.radUIVersion,
        variantName: 'trd-trade-in-vehicle-info'
      }
    })
  },
  */
  errorPage: (errorType) => {
    pageLoad({
      page: {
        pageName: `${tag}:trd:cart:trade in:error info`,
        siteSection: 'cart',
        hierarchy: 'shopping tools:trd:trade in',
        userLanguage: 'eng',
        pageNameNoVehicle: `${tag}:trd:cart:trade in:error info`,
        site: site,
        radUIVersion: window.radUIVersion,
        variantName: 'trd-errorPage'
      },
      user: {
        errorTracking: `${errorType} error`
      },
      event: {
        action: 'Error Tracking'
      }
    })
  },

  appraiseDiffVeh: () => {
    pageLoad({
      page: {
        pageName: `${tag}:trd:cart:trade in:value different vehicle`,
        siteSection: 'cart',
        hierarchy: 'shopping tools:trd:trade in',
        userLanguage: 'eng',
        pageNameNoVehicle: `${tag}:trd:cart:trade in:value different vehicle`,
        client: client,
        site: site,
        radUIVersion: window.radUIVersion,
        variantName: 'trd-trade-in-vehicle-info'
      }
    })
  },
  financeBalanceIncorrect: () => {
    pageLoad({
      page: {
        pageName: `${tag}:trd:cart:trade in:vehicle:remaining balance info:incorrect`,
        siteSection: 'cart',
        hierarchy: 'shopping tools:trd:trade in',
        userLanguage: 'eng',
        pageNameNoVehicle: `${tag}:trd:cart:trade in:vehicle:remaining balance info:incorrect`,
        client: client,
        site: site,
        radUIVersion: window.radUIVersion,
        variantName: 'trd-trade-in-vehicle-info'
      }
    })
  },
  leaseBalanceIncorrect: () => {
    pageLoad({
      page: {
        pageName: `${tag}:trd:cart:trade in:vehicle:leasing obligation info:incorrect`,
        siteSection: 'cart',
        hierarchy: 'shopping tools:trd:trade in',
        userLanguage: 'eng',
        pageNameNoVehicle: `${tag}:trd:cart:trade in:vehicle:leasing obligation info:incorrect`,
        client: client,
        site: site,
        radUIVersion: window.radUIVersion,
        variantName: 'trd-trade-in-vehicle-info'
      }
    })
  },
  fusionRecallInfo: () => {
    pageLoad({
      page: {
        pageName: `${tag}:trd:cart:trade in:vehicle info:recall flow`,
        siteSection: 'cart',
        hierarchy: 'shopping tools:trd:trade in',
        userLanguage: 'eng',
        pageNameNoVehicle: `${tag}:trd:cart:trade in:vehicle info:recall flow`,
        client: client,
        site: site,
        radUIVersion: window.radUIVersion,
        variantName: 'trd-trade-in-vehicle-select'
      }
    })
  },
  fusionRecallSelectVehicle: (toolDescriptor, year, make, model) => {
    pageLoad({
      page: {
        pageName: `${tag}:trd:cart:trade in:select vehicle:recall flow`,
        siteSection: 'cart',
        hierarchy: `shopping tools:trd:trade in`,
        userLanguage: 'eng',
        pageNameNoVehicle: `${tag}:trd:cart:trade in:select vehicle:recall flow`,
        client: client,
        site: site,
        tool: 'trd:vehicle info type',
        toolDescriptor: `trd:${toolDescriptor}`,
        radUIVersion: window.radUIVersion,
        variantName: 'trd-trade-in-start'
      },
      vehicle: {
        modelYear: `${year}`,
        nameplate: `${model}`,
        currentMake: `${make}`
      }
    })
  },

  fusionRecallAppraisal: () => {
    pageLoad({
      page: {
        pageName: `${tag}:trd:cart:trade in:appraisal:recall flow`,
        siteSection: 'cart',
        hierarchy: 'shopping tools:trd:trade in',
        userLanguage: 'eng',
        pageNameNoVehicle: `${tag}:trd:cart:trade in:appraisal:recall flow`,
        client: client,
        site: site,
        tool: 'event:trade-in',
        radUIVersion: window.radUIVersion,
        variantName: 'trd-trade-in-appraisals'
      }
    })
  }
}
