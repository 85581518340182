import React, { useContext, useState, useEffect } from 'react'
import { navigate, useQueryParams } from 'hookrouter'

import { AppContext, AppACTIONS } from '@common/context/AppContext'
import { DataContext, DataACTIONS } from '@common/context/DataContext'

import Icon from '@common/icons/Icon'
import { currencyUSD } from '@utils/filters'

import TradeinButton from '@common/pageElements/TradeinButton'

import StepHeader from '@common/pageElements/StepHeader'
import { StepHeaders, StepBody } from './Copy'
import styled from 'styled-components'
import api from '@utils/api'

import { StyledVehicleFound } from '../../styled-components/screens/StyledVehicleFound'
import { mediaQueries } from '../../styled-components/mediaQueries'
import Image from '@common/icons/Image'
import { pageLoadTags, onClick } from '@utils/analytics/index'
import RadioButtonCard from '@common/pageElements/RadioButtonCard'

const StyledFinancePath = styled.div`
  margin-bottom: -70px;
  letter-spacing: 0;
  .tradein-saved .tradein-card-wrap {
    @media ${mediaQueries.underM} {
      display: none;
    }
    &.currentCard {
      display: block;
    }
  }
  .tradein-mobile-card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mobile-card-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
    @media ${mediaQueries.overM} {
      display: none;
    }
    span {
      font-size: var(--fz-md);
      padding: 0 30px;
      letter-spacing: 1.14px;
    }
    .mobile-card-button {
      background-color: var(--secondary);
      border: 0;
      color: white;
      padding: 6px;
      width: 40px;
      height: 40px;
      border-radius: 3px;
      &.disabled {
        background-color: grey;
      }
      svg {
        width: 24px;
        height: 24px;
        path {
          fill: var(--white);
        }
      }
    }
    .mobile-submit-section {
      display: flex;
      justify-content: center;
    }
  }
  &.lincoln {
    .mobile-card-buttons-container span {
      font-size: var(--fz-md);
      font-weight: 600;
    }
    .mobile-card-button {
      border-radius: 100%;
      background: transparent;
      border: 1px solid var(--lincolnOrange);
      color: var(--lincolnOrange);
      svg {
        width: 24px;
        height: 24px;
        path {
          fill: var(--lincolnOrange);
        }
      }
      &.disabled {
        color: var(--inputGrey);
        border-color: var(--inputGrey);
        background: transparent;
        path {
          fill: var(--inputGrey);
        }
      }
    }
  }
`

const StyledFinanceCard = styled.div`
  font-size: var(--fz-xxs);

  .tradein-card-content {
    border: 0;
    margin: 0.5rem;
    background-color: white;
    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1),
      0 10px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 250px;
    padding-bottom: 40px;
    @media ${mediaQueries.underM} {
      width: 280px;
    }
  }
  .tradein-card-image {
    background-color: var(--bgGrey);
    height: 125px;
    display: flex;
    svg {
      display: block;
      margin: auto;
    }
  }
  .tradein-card-text {
    padding: 30px 20px;
    background-color: white;
    height: 150px;
  }
  .tradein-finance-method-text {
    font-family: var(--fontPrimary);
    padding-right: 1rem;
    min-height: 3rem;
  }
  &.lincoln {
    font-size: var(--fz-md);
    .tradein-card-content {
      box-shadow: none;
      border: solid 1px var(--borderLightGrey);
    }
    .tradein-subheader {
      font-weight: 600;
    }
    .tradein-card-image {
      justify-content: center;
      align-items: center;
    }
    svg {
      margin: 5px;
    }
  }
`

const SelectFinanceDesktopWrapper = styled.div`
  .tradein-button-ford-wrapper {
    button {
      margin-left: auto;
    }
  }
  .tradein-financePath {
    padding-bottom: 70px;
  }
  .tradein-card-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tradein-saved {
    padding: 70px 0;
    background: white;
  }
`

const USFordStyledStepHeader = styled(StepHeader)`
  @media ${mediaQueries.underS} {
    font-size: var(--fx-mxl2);
    margin-bottom: 20px;
    max-width: 358px;
  }
  text-align: left;
`
const SelectOwnershipTypeWrapper = styled(SelectFinanceDesktopWrapper)`
  .tradein-card-row {
    flex-direction: column;
    align-items: unset;
  }
  .tradein-card-wrapper {
    padding: 0;
  }
  .tradein-financePath {
    max-width: 656px;
    margin: auto;
  }
`

const SelectFinanceMobileWrapper = styled.div``

const StyledSavedCards = styled(StyledVehicleFound)`
  margin: 0.5rem;
  width: 250px;
  display: flex;

  @media ${mediaQueries.underM} {
    width: 280px;
  }
  .tradein-veh-card {
    padding-bottom: 18px;
    .tradein-veh-content-wrapper {
      .tradein-veh-text-container {
        padding: 1.5rem;
        .tradein-vehicle-title {
          font-size: var(--fz-md);
          font-weight: 600;
          font-family: var(--fontSecondary);
        }
        .tradein-veh-text-wrapper {
          height: 100px;
          .tradein-vehicle-title {
            margin-top: 0;
          }
        }
      }
      .tradein-buttons-column {
        height: 100px;
        justify-content: center;
        margin-bottom: 20px;
        button::after {
          box-shadow: none;
        }
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .tradein-veh-image-wrapper {
    height: 170px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
  }

  .saved-banner {
    padding: 2px 15px;
    text-align: center;
    color: white;
    text-transform: uppercase;
    background: var(--secondary);

    &.expired {
      background: var(--red);
    }
  }
`

const Card = ({
  img,
  header,
  text,
  action,
  brand,
  type,
  saved,
  vehicleImageUrl,
  vehicleMake,
  vehicleModel,
  vehicleYear,
  appraisalExpiration,
  ownershipType,
  appraisalAmount,
  remainingBalance,
  enteredBalance,
  enteredObligation,
  appraisalAcceptTimestamp,
  referralId,
  id
}) => {
  const { getReferralDetails } = useContext(AppContext)
  const { dataDispatch } = useContext(DataContext)

  let expirationDays = 'expired'
  const today = new Date().getTime()

  if (appraisalExpiration) {
    const appraisalExpirationDate = new Date(appraisalExpiration).getTime()

    if (appraisalExpirationDate > today) {
      // To calculate the no. of days between two dates
      expirationDays = Math.round(
        (appraisalExpirationDate - today) / (1000 * 3600 * 24)
      )
    }
  } else if (ownershipType === 'LEASE') {
    const leaseAppExp = new Date(appraisalAcceptTimestamp)
    leaseAppExp.setDate(leaseAppExp.getDate() + 30)
    if (leaseAppExp.getTime() > today) {
      expirationDays = Math.round(
        (leaseAppExp.getTime() - today) / (1000 * 3600 * 24)
      )
    }
  }

  const prefillAppraisalFlow = (text) => {
    onClick.vehicleOwnerShip.selectOrUpdate(text)
    if (id) {
      api
        .updatePrefillAppraisal({
          referralId,
          prefillAppraisalId: id
        })
        .then(() => {
          if (expirationDays === 'expired') {
            dataDispatch({
              type: DataACTIONS.mileageUpdated,
              payload: null
            })
          }
          getReferralDetails(referralId, id)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <div className='tradein-card-wrap'>
      {!saved ? (
        <StyledFinanceCard className={brand}>
          <div className='tradein-card-content'>
            <div className='tradein-card-image'>
              <Image img={img} />
              {brand === 'lincoln' && <Image img='vehicleIcon' />}
            </div>
            <div className='tradein-card-text'>
              <div className='tradein-subheader'>{header}</div>
              <div className='tradein-finance-method-text'>{text}</div>
            </div>
            <div className='tradein-buttons-container'>
              <TradeinButton
                type='primary'
                id={`tradein-button-${type}`}
                iconType={brand === 'ford' ? 'nextIcon' : ''}
                text='Select'
                action={action}
              />
            </div>
          </div>
        </StyledFinanceCard>
      ) : (
        <StyledSavedCards className={brand}>
          <div className='tradein-input-wrapper'>
            <div className='tradein-veh-card'>
              <div className='tradein-veh-content-wrapper'>
                {vehicleImageUrl ? (
                  <div className='tradein-veh-image-wrapper'>
                    <img className='veh-image' src={vehicleImageUrl} alt='' />
                    <p className='tradein-vehicle-tradein-subtext'>
                      Vehicle image is only an example
                    </p>
                  </div>
                ) : (
                  <div className='tradein-veh-image-wrapper'>
                    <Image
                      img={
                        brand === 'ford'
                          ? 'placeholderCar'
                          : 'lincolnPlaceholderCar'
                      }
                    />
                    <p className='tradein-no-vehicle-tradein-subtext'>
                      No Image Available
                    </p>
                  </div>
                )}
                {expirationDays === 'expired' && (
                  <div className='saved-banner expired'>OFFER EXPIRED</div>
                )}
                {expirationDays !== 'expired' && (
                  <div className='saved-banner'>
                    OFFER EXPIRES IN {expirationDays} DAYS
                  </div>
                )}
                <div className='tradein-veh-text-container'>
                  <div className='tradein-vehicle-title'>
                    {vehicleYear} {vehicleMake} {vehicleModel}
                  </div>
                  {expirationDays === 'expired' ? (
                    <div className='tradein-veh-text-wrapper'>
                      <span>{StepHeaders.financePath.saved.expired}</span>
                    </div>
                  ) : (
                    <div>
                      {ownershipType === 'OWN' && (
                        <div className='tradein-veh-text-wrapper'>
                          <span>Appraisal Amount</span>
                          <div className='tradein-vehicle-title'>
                            {currencyUSD(appraisalAmount)}
                          </div>
                        </div>
                      )}
                      {ownershipType === 'FINANCE' && (
                        <div className='tradein-veh-text-wrapper'>
                          <span>Appraisal Amount</span>
                          <div className='tradein-vehicle-title'>
                            {currencyUSD(appraisalAmount)}
                          </div>
                          {remainingBalance ? (
                            <div>
                              <span>Remaining Balance</span>
                              <div className='tradein-vehicle-title'>
                                {currencyUSD(remainingBalance)}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <span>Entered Amount</span>
                              <div className='tradein-vehicle-title'>
                                {currencyUSD(enteredBalance)}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {ownershipType === 'LEASE' && (
                        <div className='tradein-veh-text-wrapper'>
                          {remainingBalance ? (
                            <div>
                              <span>Lease Obligation Amount</span>
                              <div className='tradein-vehicle-title'>
                                {currencyUSD(remainingBalance)}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <span>Entered Amount</span>
                              <div className='tradein-vehicle-title'>
                                {currencyUSD(enteredObligation)}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {expirationDays !== 'expired' && (
                  <div className='tradein-buttons-column'>
                    <TradeinButton
                      type='primary'
                      text='Select'
                      iconType='lincolnPlus'
                      action={() =>
                        prefillAppraisalFlow('select current vehicle')
                      }
                    />
                  </div>
                )}
                {expirationDays === 'expired' && (
                  <div className='tradein-buttons-column'>
                    <TradeinButton
                      type='forward'
                      text='Update Appraisal'
                      iconType='nextIcon'
                      action={() =>
                        prefillAppraisalFlow('offer expired update appraisal')
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </StyledSavedCards>
      )}
    </div>
  )
}

const Desktop = () => {
  const { appDispatch, brand } = useContext(AppContext)
  const handleClick = (financeType) => {
    appDispatch({ type: AppACTIONS.financeTypeUpdated, payload: financeType })
    navigate('/landingpage_US')
  }

  return (
    <SelectFinanceDesktopWrapper>
      <div className='tradein-financePath'>
        <StepHeader
          mainHead={StepHeaders.financePath.main}
          subHead={StepHeaders.financePath.sub}
          maxWidth='680px'
        />
        <div className='tradein-card-row'>
          <Card
            img={brand === 'ford' ? 'ownedIcon' : 'pickupDeliveryIcon'}
            header='OWNED VEHICLE'
            text={StepBody.selectFinance[brand].owntext}
            action={() => handleClick('Own')}
            brand={brand}
            type='own'
          />
          <Card
            img={brand === 'ford' ? 'financeIcon' : 'dealerIcon'}
            header='FINANCED VEHICLE'
            text={StepBody.selectFinance[brand].financeText}
            action={() => handleClick('Finance')}
            brand={brand}
            type='finance'
          />
          <Card
            img={brand === 'ford' ? 'leaseIcon' : 'clockIcon'}
            header='LEASED VEHICLE'
            text={StepBody.selectFinance[brand].leaseText}
            action={() => handleClick('Lease')}
            brand={brand}
            type='lease'
          />
        </div>
      </div>
    </SelectFinanceDesktopWrapper>
  )
}

const Mobile = () => {
  const { appDispatch, brand } = useContext(AppContext)
  const [card, setCard] = useState(1)
  const handleClick = (financeType) => {
    appDispatch({ type: AppACTIONS.financeTypeUpdated, payload: financeType })
    navigate('/landingpage_US')
  }

  const showNextCard = () => {
    if (card === 3) {
      setCard(3) /// aka do nothing
    } else {
      setCard(card + 1)
    }
  }

  const showPreviousCard = () => {
    if (card === 1) {
      setCard(1) /// aka do nothing
    } else {
      setCard(card - 1)
    }
  }

  return (
    <SelectFinanceMobileWrapper>
      <div className={`tradein-financePath ${brand}`}>
        <StyledStepHeader
          mainHead={StepHeaders.financePath.main}
          subHead={StepHeaders.financePath.sub}
          maxWidth='680px'
        />
        <div className='tradein-mobile-card-wrapper'>
          {card === 1 && (
            <Card
              header='OWNED VEHICLE'
              img={brand === 'ford' ? 'ownedIcon' : 'pickupDeliveryIcon'}
              text={StepBody.selectFinance[brand].ownText}
              action={() => handleClick('Own')}
              brand={brand}
              type='own'
            />
          )}
          {card === 2 && (
            <Card
              header='FINANCED VEHICLE'
              img={brand === 'ford' ? 'financeIcon' : 'dealerIcon'}
              text={StepBody.selectFinance[brand].financeText}
              action={() => handleClick('Finance')}
              brand={brand}
              type='finance'
            />
          )}
          {card === 3 && (
            <Card
              header='LEASED VEHICLE'
              img={brand === 'ford' ? 'leaseIcon' : 'clockIcon'}
              text={StepBody.selectFinance[brand].leaseText}
              action={() => handleClick('Lease')}
              brand={brand}
              type='lease'
            />
          )}
          <div className='mobile-card-buttons-container'>
            <button
              className={`mobile-card-button ${card === 1 ? 'disabled' : ''}`}
              onClick={showPreviousCard}
            >
              <Icon icon='leftArrow' />
            </button>
            {brand === 'ford' ? (
              <span>{card} of 3</span>
            ) : (
              <span>{card} / 3</span>
            )}
            <button
              className={`mobile-card-button ${card === 3 ? 'disabled' : ''}`}
              onClick={showNextCard}
            >
              <Icon icon='rightArrow' />
            </button>
          </div>
        </div>
      </div>
    </SelectFinanceMobileWrapper>
  )
}

const MobileNav = ({ savedCardIndex }) => {
  const { appDispatch, brand } = useContext(AppContext)
  const [currentSavedCard, setCurrentSavedCard] = useState(0)

  const handleClick = (financeType) => {
    appDispatch({ type: AppACTIONS.financeTypeUpdated, payload: financeType })
    navigate('/landingpage_US')
  }

  const showNextCard = () => {
    if (currentSavedCard !== 2) {
      setCurrentSavedCard(currentSavedCard + 1)
      if (document.querySelector('.tradein-card-wrap.currentCard')) {
        document
          .querySelector('.tradein-card-wrap.currentCard')
          .classList.remove('currentCard')
      }
      document
        .querySelector('.tradein-saved .tradein-card-row')
        .childNodes[currentSavedCard + 1].classList.add('currentCard')
    }
  }

  const showPreviousCard = () => {
    if (currentSavedCard !== 0) {
      setCurrentSavedCard(currentSavedCard - 1)
      if (document.querySelector('.tradein-card-wrap.currentCard')) {
        document
          .querySelector('.tradein-card-wrap.currentCard')
          .classList.remove('currentCard')
      }
      document
        .querySelector('.tradein-saved .tradein-card-row')
        .childNodes[currentSavedCard - 1].classList.add('currentCard')
    }
  }

  return (
    <div className='mobile-card-buttons-container'>
      <button
        className={`mobile-card-button ${
          currentSavedCard === 0 ? 'disabled' : ''
        }`}
        onClick={showPreviousCard}
      >
        <Icon icon='leftArrow' />
      </button>
      {brand === 'ford' ? (
        <span>{currentSavedCard + 1} of 3</span>
      ) : (
        <span>{currentSavedCard} / 3</span>
      )}
      <button
        className={`mobile-card-button ${
          currentSavedCard === 2 ? 'disabled' : ''
        }`}
        onClick={showNextCard}
      >
        <Icon icon='rightArrow' />
      </button>
    </div>
  )
}
const SelectOwnershipType = () => {
  const [curValue, setCurValue] = useState(0)

  const { appDispatch, customerId, getReferralDetails } = useContext(AppContext)
  const [queryString] = useQueryParams()

  const [radioBtnObj, setRadioBtnObj] = useState([])
  const [readyRender, setReadyRender] = useState(false)

  const [financeTypeValues, setFinanceTypeValues] = useState([
    'Own',
    'Finance',
    'Lease'
  ])

  const fianceTypeObj = [
    {
      name: 'Owned Vehicle',
      text: 'My vehicle is paid off',
      img: 'ownedIcon'
    },
    {
      name: 'Financed Vehicle',
      text: 'I finance through Ford Credit or another lender',
      img: 'financeIcon'
    },
    {
      name: 'Leased Vehicle',
      text: 'I lease through Ford Credit or another lender',
      img: 'leaseIcon'
    }
  ]

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1)
  }

  function filterByStateFlow(data) {
    return data.filter((type) => type.flowState !== 'LOOKUP_NO_VALUE')
  }

  useEffect(() => {
    if (customerId) {
      api
        .getCustomerInfo(customerId)
        .then((data) => {
          if (data.length > 0) {
            const filterData = filterByStateFlow(data)
            const first3Objs = filterData.splice(0, 3)
            setRadioBtnObj(fianceTypeObj.concat(mapCustomerObj(first3Objs)))
            const savedFinanceTypes = first3Objs
              .map((type) => capitalizeFirstLetter(type.ownershipType))
              .filter((type) => type !== undefined)
            setReadyRender(true)

            setFinanceTypeValues(financeTypeValues.concat(savedFinanceTypes))
          } else {
            setRadioBtnObj(fianceTypeObj)
            setReadyRender(true)
          }
        })
        .catch((error) => {
          setRadioBtnObj(fianceTypeObj)
          setReadyRender(true)
          console.error(error)
        })
    } else {
      setRadioBtnObj(fianceTypeObj)
      setReadyRender(true)
    }
  }, [readyRender])

  const today = new Date().getTime()

  function getExpirationDate(
    appraisalExpiration,
    ownershipType,
    appraisalAcceptTimestamp
  ) {
    let expirationDays = 'expired'

    if (appraisalExpiration) {
      const appraisalExpirationDate = new Date(appraisalExpiration).getTime()

      if (appraisalExpirationDate > today) {
        // To calculate the no. of days between two dates
        expirationDays = Math.round(
          (appraisalExpirationDate - today) / (1000 * 3600 * 24)
        )
      }
    } else if (ownershipType === 'LEASE') {
      const leaseAppExp = new Date(appraisalAcceptTimestamp)
      leaseAppExp.setDate(leaseAppExp.getDate() + 30)
      if (leaseAppExp.getTime() > today) {
        expirationDays = Math.round(
          (leaseAppExp.getTime() - today) / (1000 * 3600 * 24)
        )
      }
    }

    return expirationDays
  }

  function getFinanceTypeText(type) {
    switch (type) {
      case 'OWN':
        return 'Owned'
      case 'FINANCE':
        return 'Financed'
      case 'LEASE':
        return 'Leased'
      default:
        return 'Owned'
    }
  }

  //   Financed:
  // enteredBalance or remainingBalance
  // Lease:
  // enteredObligation or totalOutstandingBalanceAmount

  function mapCustomerObj(obj) {
    const savedVehicleObj = obj.map((vehicle) => ({
      name: vehicle.vehicleMake + ' ' + vehicle.vehicleModel,
      text: getFinanceTypeText(vehicle.ownershipType) + ' vehicle',
      img: vehicle.vehicleImageUrl,
      savedVehicle: true,
      appraisalAmount: vehicle.appraisalAmount,
      enteredAmount: vehicle.enteredBalance || vehicle.remainingBalance,
      enteredObligation:
        vehicle.enteredObligation || vehicle.totalOutstandingBalanceAmount,
      expirationDate: getExpirationDate(
        vehicle.appraisalExpiration,
        vehicle.ownershipType,
        vehicle.appraisalAcceptTimestamp
      ),
      referralId: vehicle.referralId,
      appraisalId: vehicle.id
    }))
    return savedVehicleObj
  }

  const handleClick = () => {
    if (curValue) {
      console.log(curValue)
      const financeType = financeTypeValues[curValue - 1]
      appDispatch({ type: AppACTIONS.financeTypeUpdated, payload: financeType })
      // if index is more than 3, the selected card will be a saved appraisal flow
      if (curValue > 3) {
        prefillAppraisalFlow()
      } else {
        navigate('/landingpage_US')
      }
    }
  }

  const prefillAppraisalFlow = () => {
    const selectedSavedApp = radioBtnObj[curValue - 1]
    const { appraisalId, expiredDays } = selectedSavedApp

    let analyticsText = ''
    if (expiredDays === 'expired') {
      analyticsText = 'offer expired update appraisal'
    } else {
      analyticsText = 'select current vehicle'
    }
    onClick.vehicleOwnerShip.selectOrUpdate(analyticsText)

    if (appraisalId) {
      api
        .updatePrefillAppraisal({
          referralId: queryString.referralId,
          prefillAppraisalId: appraisalId
        })
        .then(() => {
          if (expiredDays === 'expired') {
            dataDispatch({
              type: DataACTIONS.mileageUpdated,
              payload: null
            })
          }
          getReferralDetails(queryString.referralId, appraisalId)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  function setChecked(ans) {
    if (typeof ans !== 'object') {
      setCurValue(ans)
    }
  }

  return (
    <SelectOwnershipTypeWrapper>
      <div className='tradein-financePath'>
        <USFordStyledStepHeader
          mainHead={StepHeaders.financePath.main}
          subHead={StepHeaders.financePath.sub}
        />

        <div className='tradein-card-row'>
          {readyRender &&
            radioBtnObj.map((obj, i) => (
              <RadioButtonCard
                savedAppraisal
                {...obj}
                id={i + 1}
                index={i + 1}
                onChange={setChecked}
                setChecked={setChecked}
                curValue={curValue}
                isHomePage
                hasDisclaimer
              />
            ))}
        </div>

        <div className='tradein-button-ford-wrapper'>
          <TradeinButton
            type='USFordPrimary'
            disabled={curValue === 0}
            text='Continue'
            iconType='nextIcon'
            action={handleClick}
          />
        </div>
      </div>
    </SelectOwnershipTypeWrapper>
  )
}

const SelectFinanceMethod = () => {
  const { dataDispatch } = useContext(DataContext)
  const { customerId, isAuthenticated, brand } = useContext(AppContext)
  const [customerObj, setCustomerObj] = useState({})

  const [queryString] = useQueryParams()

  const [currentSavedCard, setCurrentSavedCard] = useState(0)

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    pageLoadTags.ownershipStatus()
    function handleResize() {
      setWidth(window.innerWidth)
    }
    if (brand !== 'ford') {
      if (customerId) {
        api
          .getCustomerInfo(customerId)
          .then((data) => {
            if (data.length > 0) {
              if (data.length > 3) {
                setLoadedCustObj(false)
                setCustomerObj(data.slice(0, 3))
              } else {
                setCustomerObj(data)
                setLoadedCustObj(false)
              }
              document
                .querySelector('.tradein-saved .tradein-card-row')
                .childNodes[currentSavedCard].classList.add('currentCard')
            }
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
    dataDispatch({
      type: DataACTIONS.appraisalIdUpdated,
      payload: queryString.appraisalId
    })

    dataDispatch({
      type: DataACTIONS.languageUpdated,
      payload: queryString.lang
    })

    dataDispatch({
      type: DataACTIONS.marketUpdated,
      payload: queryString.market
    })

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [dataDispatch, queryString]) // Empty array ensures that effect is only run on mount and unmount

  return (
    <StyledFinancePath>
      {brand === 'ford' ? (
        <SelectOwnershipType />
      ) : width > 768 ? (
        <Desktop />
      ) : (
        <Mobile />
      )}
      {isAuthenticated && brand !== 'ford' && (
        <SelectFinanceDesktopWrapper>
          {customerObj.length && (
            <div className='tradein-saved'>
              <StyledStepHeader mainHead={StepHeaders.financePath.saved.main} />

              <div className='tradein-card-wrapper'>
                <div className='tradein-card-row'>
                  {customerObj.map((obj, i) => (
                    <Card saved key={i} referralId {...obj} />
                  ))}
                </div>
                <MobileNav />
              </div>
            </div>
          )}
        </SelectFinanceDesktopWrapper>
      )}
    </StyledFinancePath>
  )
}
export default SelectFinanceMethod
