import React from 'react'
import { navigate } from 'hookrouter'

import TradeinButton from '../pageElements/TradeinButton'
import styled from 'styled-components'
import StepHeader from '@common/pageElements/StepHeader'

const StyledHealthPage = styled.div`
  font-size: var(--fz-xxs);
  font-family: var(--fontPrimary);
  text-align: center;
  p.tradein-subtext {
    margin-bottom: 50px;
  }
`

const Health = () => {
  return (
    <StyledHealthPage>
      <StepHeader mainHead='Everything is normal!' />
      <TradeinButton
        text='Return to Trade-in'
        type='back'
        action={() => navigate('/')}
      />
    </StyledHealthPage>
  )
}

export default Health
