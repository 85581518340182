const fordTag = 'fv'
const lincolnTag = 'ln'
const tag = window.location.hostname.includes('lincoln') ? lincolnTag : fordTag
function resetOnClick() {
  try {
    window.digitaldata.onclick = {
      onclickLinkName: '',
      onclick: ''
    }
  } catch (err) {
    console.log('Analytics blocked', err)
  }
}

export default {
  vehicleCondition: (toolTipId) => {
    try {
      resetOnClick()
      window.digitaldata.onclick.onclickLinkName = `${tag}:trd:cart:trade in:vehicle:condition:iball click`
      window.digitaldata.onclick.onclick = `trd:cart:trade in:condition:iball click:${toolTipId}`
      window._satellite.track('trd-onclick')
    } catch (err) {
      console.log('Analytics blocked', err)
    }
  },
  selectVehicle: () => {
    try {
      resetOnClick()
      window.digitaldata.onclick.onclickLinkName = `${tag}:trd:cart:trade in:vehicle info:incorrect`
      window.digitaldata.onclick.onclick =
        'trd:cart:trade in:vehicle info:incorrect'
      window._satellite.track('trd-onclick')
    } catch (err) {
      console.log('Analytics blocked', err)
    }
  },
  // vehicleSummary: (action) => {
  //   const onClickAction =
  //     action === 'appraise different vehicle'
  //       ? 'appraise different vehicle'
  //       : `edit:${action}`
  //   try {
  //     resetOnClick()
  //     window.digitaldata.onclick.onclickLinkName =
  //       'fv:trd:cart:trade in:vehicle:summary:content:action'
  //     window.digitaldata.onclick.onclick = `trd:cart:trade in:summary:content:{{${onClickAction}}}`
  //     window._satellite.track('trd-onclick')
  //   } catch (err) {
  //     console.log('Analytics blocked', err)
  //   }
  // },
  financeBalanceIncorrect: (actualBalance, reason) => {
    try {
      resetOnClick()
      window.digitaldata.onclick.onclickLinkName = `${tag}:trd:cart:trade in:finance:vehicle:remaining balance info:incorrect:next`
      window.digitaldata.onclick.onclick = `trd:cart:trade in:remaining balance info:incorrect:actual amount:${actualBalance}:reason for discrepancy:${reason}`
      window._satellite.track('trd-onclick')
    } catch (err) {
      console.log('Analytics blocked', err)
    }
  },
  leaseBalanceIncorrect: (actualBalance, reason) => {
    try {
      resetOnClick()
      window.digitaldata.onclick.onclickLinkName = `${tag}:trd:cart:trade in:finance:vehicle:leasing obligation info:incorrect:next`
      window.digitaldata.onclick.onclick = `trd:cart:trade in:leasing obligation info:incorrect:actual amount:${actualBalance}:reason for discrepancy:${reason}`
      window._satellite.track('trd-onclick')
    } catch (err) {
      console.log('Analytics blocked', err)
    }
  },
  tradeInAppraisal: {
    appraiseDifferentVehicle: (type) => {
      try {
        resetOnClick()
        window.digitaldata.onclick.onclickLinkName = `${tag}:trd:cart:trade in:appraisal:content:action`
        window.digitaldata.onclick.onclick = `trd:cart:trade in:${type.toLowerCase()}:appraisal:content:appraise different vehicle`
        window._satellite.track('trd-onclick')
      } catch (err) {
        console.log('Analytics blocked', err)
      }
    },
    addToCart: (type) => {
      try {
        resetOnClick()
        window.digitaldata.onclick.onclickLinkName = `${tag}:trd:cart:trade in:appraisal:add to cart`
        window.digitaldata.onclick.onclick = `trd:cart:trade in:${type.toLowerCase()}:appraisal:content:add to cart`
        window._satellite.track('trd-tradein-addcart')
      } catch (err) {
        console.log('Analytics blocked', err)
      }
    },
    declineBackToCart: (type) => {
      try {
        resetOnClick()
        window.digitaldata.onclick.onclickLinkName = `${tag}:trd:cart:trade in:appraisal:content:action`
        window.digitaldata.onclick.onclick = `trd:cart:trade in:${type.toLowerCase()}:appraisal:content:decline and back to cart`
        window._satellite.track('trd-onclick')
      } catch (err) {
        console.log('Analytics blocked', err)
      }
    }
  },
  appraiseDifferentVehicleModal: {
    selectYesOrNo: (type) => {
      try {
        resetOnClick()
        window.digitaldata.onclick.onclickLinkName = `${tag}:trd:cart:trade in:value different vehicle`
        window.digitaldata.onclick.onclick = `trd:cart:trade in:value different vehicle:cta:${
          type === 'yes' ? 'yes im sure' : 'cancel'
        }`
        window._satellite.track('trd-onclick')
      } catch (err) {
        console.log('Analytics blocked', err)
      }
    }
  },
  cancelModal: {
    exitCart: (type) => {
      try {
        resetOnClick()
        window.digitaldata.onclick.onclickLinkName = `${tag}:trd:cart:trade in:cancel overlay:content action`
        window.digitaldata.onclick.onclick = `trd:cart:trade in:cancel overlay:${type}`
        window._satellite.track('trd-onclick')
      } catch (err) {
        console.log('Analytics blocked', err)
      }
    }
  },
  vehicleOwnerShip: {
    selectOrUpdate: (type) => {
      try {
        resetOnClick()
        window.digitaldata.onclick.onclickLinkName = `${tag}:trd:cart:trade in:ownership status:content action`
        window.digitaldata.onclick.onclick = `trd:cart:trade in:ownership status:${type.toLowerCase()}`
        window._satellite.track('trd-onclick')
      } catch (err) {
        console.log('Analytics blocked', err)
      }
    }
  },

  fusionRecallCancel: {
    exitCart: (type) => {
      try {
        resetOnClick()
        window.digitaldata.onclick.onclickLinkName = `${tag}:trd:cart:trade in:cancel overlay:recall flow:content action`
        window.digitaldata.onclick.onclick = `trd:cart:trade in:cancel overlay:${type}`
        window._satellite.track('trd-onclick')
      } catch (err) {
        console.log('Analytics blocked', err)
      }
    }
  },
  fusionRecallSelectVehicle: () => {
    try {
      resetOnClick()
      window.digitaldata.onclick.onclickLinkName = `${tag}:trd:cart:trade in:vehicle info:incorrect:recall flow`
      window.digitaldata.onclick.onclick =
        'trd:cart:trade in:vehicle info:incorrect:recall flow'
      window._satellite.track('trd-onclick')
    } catch (err) {
      console.log('Analytics blocked', err)
    }
  }
}
