import React, { useContext } from 'react'
import { AppContext } from '../common/context/AppContext'
import TradeinLink from '../common/pageElements/TradeinLink'

import { footerPrivacy, footerTC } from './FooterCopy'
import { getTCUrl, getFusionRecallTCUrl } from '../../constants/staticUrls'
import styled from 'styled-components'
import { navigate } from 'hookrouter'

const StyledFooter = styled.footer`
  && {
    .footer {
      background: none;
      a {
        text-transform: none;
        line-height: 16px;
        margin-right: 16px;
        color: black;
      }
    }
  }
  .footer {
    flex-shrink: 0;
    background-color: white;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
    flex-wrap: wrap;
    text-align: center;
    max-width: 700px;
    width: 100%;
  }
`
const Footer = () => {
  const { language, market, brand, application } = useContext(AppContext)
  const isFusionRecallFlow = application === 'FUSION_RECALL'
  const lang =
    footerPrivacy.hasOwnProperty(language) || footerTC.hasOwnProperty(language)
      ? language
      : 'en_us'

  const linkBrand =
    lang === 'en_us'
      ? footerPrivacy[lang][brand || 'ford']
      : footerPrivacy[lang]

  function handleTnC() {
    if (market === 'US') {
      isFusionRecallFlow
        ? window.open('/fusionrecalltnc')
        : window.open('/termsandconditionsUS')
    } else {
      getTCUrl(market, language)
    }
  }

  return (
    <StyledFooter className={`${brand} ${market}`}>
      {brand !== 'lincoln' ? (
        <div className='footer'>
          <TradeinLink
            ariaLabel={linkBrand.ariaLabel}
            type='primary'
            text={linkBrand.text}
            link={linkBrand.link}
          />
        </div>
      ) : (
        <div className='footer'>
          <TradeinLink
            ariaLabel={footerPrivacy[lang].ariaLabel}
            type='secondary'
            text='Privacy Policy'
            link='https://www.lincoln.com/help/privacy-terms/#privacy'
          />
        </div>
      )}
    </StyledFooter>
  )
}

export default Footer
