import styled from 'styled-components'
import TradeinLink from '@common/pageElements/TradeinLink'

import { mediaQueries } from '../../styled-components/mediaQueries'

export const StyledTradeinAppraisal = styled.div`
  && {
    &.ford {
      @media ${mediaQueries.underM} {
        background: none;
      }
      .tradein-appraisal-content {
        margin-top: -28px;
      }
    }
    .tradein-appraisal {
      &.ford {
        max-width: 656px;
        margin: auto;
        hr.tradein-appraisal-divider {
          border: 0;
          border-bottom: var(--borderInput);
        }
        .tradein-appraisal-header {
          text-align: left;
          margin: 0;
        }
        h2.tradein-currency-title {
          margin-bottom: 32px;
        }
        .tradein-currency-title {
          text-align: left;
          color: var(--fordBlue);
        }
        .tradein-currency-title.tradein-xl {
          font-size: 46px;
          font-weight: 400;
          line-heigh: 52px;
          margin-bottom: 8px;
          @media ${mediaQueries.underS} {
            margin-top: 30px;
          }
        }
        .tradein-currency-title.tradein-l,
        .tradein-currency-title.tradein-m {
          font-size: 34px;
          line-height: 40px;
          font-weight: 400;
          color: var(--primary);
        }
        .tradein-subtext {
          padding: 0px;
          max-width: 100%;
          margin-bottom: 30px;
          color: var(--fordBlue);
          a {
            font-weight: normal !important;
            color: var(--buttonBlue);
          }
          div {
            margin-left: 0px;
          }
          @media ${mediaQueries.underS} {
            margin-bottom: 20%;
          }
        }
        .appraisalCar-subtext {
          text-align: right;
          margin-bottom: 30px;
        }
        .tradein-appraisal-main {
          max-width: 100%;
          width: 100%;
          padding: 30px 0;
        }
        .tradein-appraisal-cal .tradein-appraisal-cal-row {
          max-width: 100%;
          color: #121212;
          .tradein-appraisal-cal-label {
            max-width: 100%;
          }
          .tradein-appraisal-cal-value {
            font-weight: 400;
          }
        }
        button.back {
          background: transparent;
          flex-direction: row-reverse;
          span {
            padding-left: 0;
            padding-right: 12px !important;
          }
          svg g {
            fill: var(--buttonBlue);
          }
        }
        .button-text {
          padding-right: 6px;
        }
      }
      .appraisalRangeGrid {
        border-radius: 47px;
        background: linear-gradient(to right, #388cf2, #339b33);
        padding: 15px 25px;
        display: flex;
        justify-content: space-between;
        justify-items: center;
        color: #fff;
        font-size: 16px;
        font-family: var(--fontPrimary);
        @media ${mediaQueries.underM} {
          font-size: 14px;
          padding: 10px 8px;
        }
        .rangeVal {
          font-weight: 700;
          margin-left: 2px;
        }
      }
      .estimatedTradeinVal {
        width: 225.62px;
        height: 98.53px;
        margin: 0 auto;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        div {
          font-size: var(--fz-sm);
          color: var(--primary);
          letter-spacing: normal;
        }
        div:nth-child(2) {
          font-size: var(--fz-xl);
          line-heigh: 32px;
        }
      }
      .rangeNote {
        max-width: 592px;
        margin: 20px auto 0px;
        text-align: center;
        color: var(--primary);
        font-size: var(--fz-sm);
        font-family: var(--fontPrimary);
        letter-spacing: 0.5px;
        line-height: 20px;
      }
      .caretReverse {
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid #fff;
        margin-top: -16px;
      }
    }
    position: relative;
    background: none;
    .tradein-buttons-column {
      margin-top: 32px;
      button {
        margin-top: 0;
        margin-bottom: 32px;
      }
    }
    @media ${mediaQueries.underM} {
      background: linear-gradient(rgb(242, 242, 242) 45%, white 0%) repeat;
    }
    @media ${mediaQueries.underS} {
      .tradein-subtext {
        position: relative;
        z-index: 1;
        padding: 10px;
        max-width: 574px;
        margin-bottom: 20px;
      }
    }
    .tradein-subtext {
      position: relative;
      z-index: 1;
      padding: 10px;
      max-width: 574px;
      a {
        font-weight: 600;
      }
    }
    .tradein-appraisal-cal {
      width: 100%;
      font-size: var(--fz-xxs);
      margin: 30px auto;
      margin-top: 0;
      .tradein-appraisal-cal-row {
        display: flex;
        justify-content: space-between;
        max-width: 430px;
        width: 100%;
        font-size: var(--fz-xxs);
        letter-spacing: 1px;
        line-height: 1.91;
        position: relative;
        margin: 2px auto;
        &.tradein-appraisal-cal-total {
          margin-top: 20px;
          padding: 20px 0;
          border-top: var(--borderInput);
          a {
            top: 40px !important;
          }
        }
        .tradein-appraisal-cal-label {
          font-size: var(--fz-md);
          max-width: 300px;
          @media ${mediaQueries.underM} {
            max-width: 250px;
          }
          a {
            font-size: 8px;
            color: #2861a4;
            text-decoration: none;
            position: absolute;
            top: 20px;
          }
        }
        .tradein-appraisal-cal-value {
          font-size: var(--fz-md);
          font-weight: bold;
        }
      }
    }

    .tradein-appraisal-main {
      padding: 10px 0 30px 0;
      max-width: 640px;
      margin: 0 auto;
      width: 90%;
    }

    .tradein-appraisalCar {
      text-align: center;
      font-size: var(--fz-xxs);
      color: var(--primary);
      img {
        width: 100%;
        height: auto;
        margin: 3% 0;
        max-width: 650px;
        margin-bottom: -130px;
        @media ${mediaQueries.underS} {
          margin-bottom: 10px;
        }
      }
      .appraisalCar-subtext {
        margin-top: 15px;
      }
      img + .appraisalCar-subtext {
        @media ${mediaQueries.underS} {
          margin-top: -75px;
        }
      }
      svg {
        width: 100%;
      }
      &.availableImg {
        max-height: 500px;
        margin-top: -150px;
        @media ${mediaQueries.underM} {
          margin-top: -100px;
        }
      }
      ~ .appraisalCar-subtext {
        font-size: var(--fz-xxs);
      }
    }
    .tradein-currency-title {
      &.tradein-xl {
        margin-top: auto;
      }
      @media ${mediaQueries.underS} {
        margin-top: 0;
      }
    }
    .tradein-divider {
      width: 50%;
      height: 1px;
      margin: 30px auto 10px;
      border-top: 1px solid #6e6e6e;
      max-width: 600px;
    }
    .appraisal-saved {
      display: flex;
      justify-content: center;
      line-height: 1.91;
      font-size: var(--fz-xxs);
      padding: 8px 0 5px 0;
      svg {
        margin-right: 8px;
      }
    }
    .tradein-buttons-container {
      margin-top: 0;
      justify-content: space-evenly;
      max-width: 500px;
      button {
        box-shadow: none;
      }
    }
    .recall-card {
      display: flex;
      align-items: flex-start;
      background-color: #f8f8f8;
      border-radius: 8px;
      padding: 32px;
      align-self: strecth;
    }
    .recall-content .title {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      margin-bottom: 20px;
    }
    .recall-content p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 20px;
    }
    .number-circle {
      background-color: #9ca3ad;
      color: #fff;
      width: 25px;
      height: 25px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      font-weight: normal;
      margin-right: 10px;
    }
    .number-circle-row {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
    /* currently not in use */
    .tradein-appraisal-save {
      margin: 50px -30px 0 -30px;
      flex-direction: column;
      padding: 40px;
      padding-bottom: 0;
      background: $bg-grey;
      width: 100%;
      .tradein-subtext {
        margin-top: 10px;
      }
      .no-img {
        font-size: $font-s;
      }
    }
  }
  &.lincoln {
    .tradein-appraisalCar svg {
      width: auto;
    }
    .tradein-currency-title {
      text-align: center;
      font-family: var(--fontPrimary);
      font-size: var(--fz-xxxl);
      font-weight: normal;
      &.tradein-xl {
        font-size: var(--fz-xxxl);
        letter-spacing: 2px;
      }
      &.tradein-l {
        font-size: var(--fx-mxl);
        line-height: 1.25;
        letter-spacing: 3.53px;
        font-weight: bold;
        @media ${mediaQueries.underM} {
          font-size: var(--fz-xxl);
        }
      }
      &.tradein-m {
        font-size: var(--fx-mxl);
        letter-spacing: 2px;
        font-weight: bold;
        @media ${mediaQueries.underM} {
          font-size: var(--fz-xxl);
        }
      }
    }
    .tradein-appraisal-cal {
      .tradein-appraisal-cal-row {
        margin-top: 10px;
        font-family: var(--fontPrimary);
        &.tradein-appraisal-cal-total {
          border-bottom: var(--borderInput);
          .tradein-appraisal-cal-label {
            margin-top: 0;
            display: flex;
            align-items: center;
          }
          padding-bottom: 15px;
        }
        .tradein-appraisal-cal-label {
          font-size: var(--fz-md);
          font-weight: bold;
          line-height: 0.875;
          letter-spacing: 1.5px;
          margin: 15px 0px 0px 0px;
          text-transform: uppercase;
        }
        .tradein-appraisal-cal-value {
          font-size: var(--fz-md);
          font-weight: normal;
          margin-top: 6px;
        }
      }
    }
  }
`

export const StyledTradeinLink = styled(TradeinLink)`
  && {
    font-size: inherit;
    display: inline;
  }
`
