import React, { useState, useContext, useEffect } from 'react'
import { AppContext, AppACTIONS } from '@common/context/AppContext'
import { DataContext } from '@common/context/DataContext'
import TradeinLink from '../pageElements/TradeinLink'
import TradeinButton from '../pageElements/TradeinButton'
import { StyledCancelModal } from '../../styled-components/screens/StyledCancelModal'
import { StyledVehicleFound } from '../../styled-components/screens/StyledVehicleFound'
import { StyledTradeinAppraisal } from '../../styled-components/screens/StyledTradeinAppraisal'

import ScrollToTop from '@common/pageElements/ScrollToTop'
import StepHeader from '@common/pageElements/StepHeader'
import Image from '@common/icons/Image'

import api from '@utils/api'
import * as filters from '@utils/filters'
import {
  sorryCannotAppraiseCopy,
  vehicleNotFoundCopy,
  errorCopy
} from './MessageCopy'
import styled from 'styled-components'
import { mediaQueries } from '../../styled-components/mediaQueries'
import { pageLoadTags, onClick } from '@utils/analytics/index'

const CannotAppraiseWrapper = styled.div`
  .sorryCannotAppraise {
    font-size: var(--fz-xxs);
    font-family: var(--fontPrimary);
    text-align: center;
    .secondary-link-text {
      font-size: var(--fz-md);
    }
    p.tradein-subtext {
      margin-bottom: 50px;
    }
    .error-text {
      max-width: 300px;
      @media ${mediaQueries.underM} {
      }
    }

    @media ${mediaQueries.underM} {
      position: relative;
      top: 0%;
    }

    .errorInvalidRef {
      max-width: 656px;
      margin: auto;
      align-items: flex-end;
      .button-text {
        padding-left: 10px;
        font-weight: normal;
      }
    }
  }
`

const VehicleNotFoundEUWrapper = styled.div`
  .vehicleNotFound {
    width: 100%;
    max-width: 650px;
    margin: auto;
    font-size: var(--fz-xxs);
    .tradein-buttons-container {
      max-width: none;
    }
    p.tradein-subtext {
      margin-bottom: 50px;
    }

    h3.tradein-title {
      text-align: left;
      text-transform: none;
      font-weight: 400;
      font-size: var(--fz-xxl);
      line-height: 40px;
      letter-spacing: 0;
      color: var(--secondary);
      padding-bottom: 32px;
      color: var(--fordBlue);
      @media ${mediaQueries.underS} {
        padding-bottom: 16px;
        max-width: 300px;
      }
    }
    .tradein-subtitle {
      margin: 0px;
      text-align: left;
      max-width: none;
      color: rgb(0, 20, 46);
      line-height: 24px;
      font-size: 20px;
    }
    .tradein-vehicle-info {
      margin: 32px 0;
      color: #00142e;
      > .tradein-buttons-column {
        margin: 0;
      }
      .tradein-vehicle-info-row {
        display: flex;
        -moz-box-pack: justify;
        justify-content: space-between;
        font-size: 20px;
        margin-bottom: 16px;
        width: 100%;

        margin-bottom: 16px;
        width: 100%;
        span {
          font-weight: normal;
        }
      }
    }
  }
`

const CannotAppraiseFordUS = styled(StyledVehicleFound)`
  &&&& {
    .tradein-button-ford-wrapper {
      flex-direction: column;
      margin-top: 32px;

      button {
        margin-bottom: 30px;
      }
    }
    .tradein-vehicle-tradein-subtext {
      font-size: 10px;
    }
    button.back {
      flex-direction: row-reverse;
      span.button-text {
        padding-right: 12px !important;
        padding-left: 0 !important;
      }
      svg g {
        fill: var(--buttonBlue);
      }
    }
  }
`

function Message({ type, prevScreen, exit, isDecline }) {
  const {
    appState,
    appDispatch,
    browserLang,
    market,
    language,
    resetApp,
    exitUrl,
    brand,
    application,
    buildShowroomUrl,
    referralId
  } = useContext(AppContext)

  const { dataState, dataDispatch } = useContext(DataContext)
  const langSet = Object.keys(errorCopy)
  const hostName = window.location.host

  const domainExt = hostName.substring(hostName.length - 2)
  const showAreYouSureModal = appState.showAreYouSureModal
  const [showAreYouSure, setShowAreYouSure] = useState(false)

  useEffect(() => {
    if (type === 'sorryCannotAppraise') pageLoadTags.errorPage('appraisal')
  }, [type])

  const lang = 'en_us'

  let errorLang = 'en_us'

  if (!langSet.includes(errorLang)) errorLang = 'en_gb'

  function handleApiCall(apiRoute, apiPayload) {
    api[apiRoute](apiPayload)
      .then((data) => {
        if (market === 'US') {
          window.location.assign(exitUrl)
        } else {
          dataDispatch({
            type: 'SHOW_LOADING_SPINNER',
            payload: false
          })
          window.location.assign(exitUrl)
        }
      })
      .catch((error) => {
        console.log(error)
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
      })
  }

  function closeAppAndAddToCart() {
    let apiRoute
    let apiPayload

    dataDispatch({
      type: 'SHOW_LOADING_SPINNER',
      payload: true
    })

    if (market === 'US') {
      if (application === 'CART') {
        apiRoute = 'acceptAppraisalUS'
        apiPayload = {
          appraisalId: dataState.appraisalId,
          referralId: referralId
        }
      } else {
        apiRoute = 'deleteAppraisal'
        apiPayload = {
          appraisalId: dataState.appraisalId
        }
      }

      handleApiCall(apiRoute, apiPayload)
    } else {
      apiRoute = 'acceptAppraisalEU'
      apiPayload = {
        appraisalId: dataState.appraisalId
      }
      handleApiCall(apiRoute, apiPayload)
    }
  }

  function exitAreYouSureModal() {
    onClick.appraiseDifferentVehicleModal.selectYesOrNo('no')

    if (showAreYouSureModal) {
      appDispatch({
        type: AppACTIONS.showAreYouSureModalUpdated,
        payload: false
      })
    } else if (showAreYouSure) {
      setShowAreYouSure(false)
    }
  }

  function exitApp(exit) {
    onClick.appraiseDifferentVehicleModal.selectYesOrNo('yes')
    if (exit || isDecline) {
      resetApp(true, dataState.appraisalId)
    } else {
      resetApp(false)
    }
  }

  function goBack(invalidRefUS) {
    if (invalidRefUS !== 'eu') {
      invalidRefUS
        ? window.location.assign('https://www.ford.com/')
        : window.history.go(-2)
    } else {
      window.history.go(-1)
    }
  }

  switch (type) {
    case 'areYouSure':
      return (
        <div>
          <StyledCancelModal
            showModal={showAreYouSureModal || showAreYouSure}
            closeModal={() => exitAreYouSureModal()}
            updatedDesign={true}
            headerText={
              brand === 'ford'
                ? 'Are you sure you want to leave this page?'
                : 'Are You Sure?'
            }
          >
            <div className='tradein-are-you-sure'>
              <div className='tradein-cancel-modal-subtitle'>
                {exit ? (
                  <p>
                    Any information you entered will be lost and nothing will be
                    added to your cart.
                  </p>
                ) : (
                  <p>
                    By appraising a new vehicle you will lose all the
                    information you entered on this current vehicle.
                  </p>
                )}
              </div>
              <div className='tradein-buttons-container'>
                <TradeinButton
                  type={brand === 'ford' ? 'USFordPrimary' : 'primary'}
                  text={
                    brand === 'ford' ? 'Yes, leave this page' : "Yes, I'm sure"
                  }
                  action={() => exitApp(exit)}
                />
                {brand === 'ford' ? (
                  <TradeinButton
                    type={brand === 'ford' ? 'USFordBack' : 'primary'}
                    text='Continue appraisal'
                    action={() => exitAreYouSureModal()}
                  />
                ) : (
                  <TradeinLink
                    type='secondary'
                    text={exit ? 'Continue Appraisal' : 'Cancel'}
                    action={() => exitAreYouSureModal()}
                  />
                )}
              </div>
            </div>
          </StyledCancelModal>
        </div>
      )

    case 'sorryCannotAppraise':
      return (
        <CannotAppraiseWrapper>
          <ScrollToTop />
          <div className='sorryCannotAppraise'>
            <StepHeader
              mainHead={sorryCannotAppraiseCopy['en_us'].header}
              maxWidth='680px'
            />
            <div className='tradein-subtext'>
              {market === 'US' && application !== 'CART'
                ? sorryCannotAppraiseCopy['en_us'].showroom.subtext
                : sorryCannotAppraiseCopy['en_us'].subtext}
            </div>
            <div className='tradein-buttons-column'>
              <TradeinButton
                type='primary'
                ariaLabel={
                  market === 'US' && application !== 'CART'
                    ? sorryCannotAppraiseCopy['en_us'].showroom.buttonAriaLabel
                    : sorryCannotAppraiseCopy['en_us'].buttonAriaLabel
                }
                text={
                  market === 'US' && application !== 'CART'
                    ? sorryCannotAppraiseCopy['en_us'].showroom.button
                    : sorryCannotAppraiseCopy['en_us'].button
                }
                action={() => closeAppAndAddToCart()}
                iconType='addIcon'
              />
              {market === 'US' && (
                <StyledCancelModal
                  headerText='Are You Sure?'
                  showModal={showAreYouSure}
                  closeModal={() => setShowAreYouSure(false)}
                >
                  <div className='tradein-cancel-modal-subtitle'>
                    By appraising a new vehicle you will lose all the
                    information you entered on this current vehicle.
                  </div>
                  <div className='tradein-buttons-container'>
                    <TradeinButton
                      text="Yes I'm Sure"
                      type='primary'
                      action={() => resetApp(false)}
                    />
                    <TradeinLink
                      type='secondary'
                      text='Cancel'
                      action={() => setShowAreYouSure(false)}
                    />
                  </div>
                </StyledCancelModal>
              )}
              {market === 'US' && (
                <TradeinButton
                  text='Appraise Different Vehicle'
                  type='forward'
                  action={() => setShowAreYouSure(true)}
                />
              )}
            </div>
          </div>
        </CannotAppraiseWrapper>
      )
    case 'sorryCannotAppraiseFordUS':
      return (
        <StyledTradeinAppraisal className={brand}>
          <div className={`tradein-appraisal ${brand} ${market}`}>
            <ScrollToTop />
            <div className='sorryCannotAppraise'>
              <StepHeader
                mainHead={sorryCannotAppraiseCopy[lang].header}
                maxWidth='680px'
              />

              {dataState.vehicleImageUrl ? (
                <div className='tradein-appraisalCar availableImg'>
                  <img
                    className='appraisalCarImg'
                    alt='Vehicle'
                    src={dataState.vehicleImageUrl}
                  />
                  <div className='appraisalCar-subtext'>
                    {brand === 'lincoln' && '*'}Vehicle image is only an example
                  </div>
                </div>
              ) : (
                <div className='tradein-appraisalCar defaultImg'>
                  <Image
                    img={
                      brand === 'ford'
                        ? 'placeholderCar'
                        : 'lincolnPlaceholderCar'
                    }
                  />
                  <div className='appraisalCar-subtext'>
                    *No Image Available
                  </div>
                </div>
              )}
              <div className='tradein-subtext'>
                {market === 'US' && application !== 'CART'
                  ? sorryCannotAppraiseCopy['en_us'].showroom.subtext
                  : sorryCannotAppraiseCopy[lang].subtext}
              </div>
              <div className='tradein-buttons-column'>
                <TradeinButton
                  type='USFordPrimary'
                  ariaLabel={
                    market === 'US' && application !== 'CART'
                      ? sorryCannotAppraiseCopy['en_us'].showroom
                          .buttonAriaLabel
                      : sorryCannotAppraiseCopy['en_us'].buttonAriaLabel
                  }
                  text={
                    market === 'US' && application !== 'CART'
                      ? sorryCannotAppraiseCopy['en_us'].showroom.button
                      : sorryCannotAppraiseCopy['en_us'].button
                  }
                  action={() => closeAppAndAddToCart()}
                  iconType='nextIcon'
                />
                <TradeinButton
                  type='USFordBack'
                  text='Appraise a different vehicle'
                  iconType='nextIcon'
                  action={() => setShowAreYouSure(true)}
                />

                <StyledCancelModal
                  showModal={showAreYouSure}
                  closeModal={() => exitAreYouSureModal()}
                  updatedDesign={true}
                  headerText={'Are you sure you want to leave this page?'}
                >
                  <div className='tradein-are-you-sure'>
                    <div className='tradein-cancel-modal-subtitle'>
                      {exit ? (
                        <p>
                          Any information you entered will be lost and nothing
                          will be added to your cart.
                        </p>
                      ) : (
                        <p>
                          By appraising a new vehicle you will lose all the
                          information you entered on this current vehicle.
                        </p>
                      )}
                    </div>
                    <div className='tradein-buttons-container'>
                      <TradeinButton
                        type='USFordPrimary'
                        text={'Yes, leave this page'}
                        action={() => exitApp(false)}
                      />
                      <TradeinButton
                        type={'USFordBack'}
                        text='Continue appraisal'
                        action={() => exitAreYouSureModal()}
                      />
                    </div>
                  </div>
                </StyledCancelModal>
              </div>
            </div>
          </div>
        </StyledTradeinAppraisal>
      )
    case 'sorryCannotAppraiseFusionRecall':
      return (
        <StyledTradeinAppraisal className={brand}>
          <div className={`tradein-appraisal ${brand} ${market}`}>
            <ScrollToTop />
            <div className='sorryCannotAppraise'>
              <StepHeader
                mainHead='We Are Sorry'
                subHead="We can't provide an estimate at this time. But don't worry – we can still assist you."
                maxWidth='656px'
              />

              {dataState.vehicleImageUrl ? (
                <div className='tradein-appraisalCar availableImg'>
                  <img
                    className='appraisalCarImg'
                    alt='Vehicle'
                    src={dataState.vehicleImageUrl}
                  />
                  <div className='appraisalCar-subtext'>
                    {brand === 'lincoln' && '*'}Vehicle image is only an example
                  </div>
                </div>
              ) : (
                <div className='tradein-appraisalCar defaultImg'>
                  <Image
                    img={
                      brand === 'ford'
                        ? 'placeholderCar'
                        : 'lincolnPlaceholderCar'
                    }
                  />
                  <div className='appraisalCar-subtext'>
                    *No Image Available
                  </div>
                </div>
              )}

              <div className='recall-card'>
                <div className='recall-content'>
                  <p>
                    <strong>
                      Contact the Ford Customer Relationship Center
                    </strong>{' '}
                    at
                    <a style={{ paddingLeft: 5 }} href='tel:1-833-807-3673'>
                      1-833-807-3673
                    </a>{' '}
                    to lock in your offical statutory refund amount. Please have
                    this information ready when you make the call:
                  </p>
                  <div className='number-circle-row'>
                    <p>
                      <span className='number-circle'>1</span>Reference recall
                      number 23S33
                    </p>
                  </div>
                  <div className='number-circle-row'>
                    <p>
                      <span className='number-circle'>2</span>Vehicle
                      Identification Number (VIN)
                    </p>
                  </div>
                  <div className='number-circle-row'>
                    <p>
                      <span className='number-circle'>3</span>Current vehicle
                      mileage
                    </p>
                  </div>
                  <p>
                    Representatives are available Monday through Friday:
                    8:30AM-5:30PM (Eastern Time)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </StyledTradeinAppraisal>
      )
    case 'errorMissingRef':
      return (
        <CannotAppraiseWrapper>
          <ScrollToTop />
          <div className='sorryCannotAppraise'>
            <StepHeader
              mainHead={errorCopy[errorLang].header}
              maxWidth='680px'
              subHead={errorCopy[errorLang].subtext}
            />

            <div className='tradein-buttons-column'>
              <TradeinButton
                text={errorCopy[errorLang].back}
                type='back'
                action={() => goBack()}
              />
            </div>
          </div>
        </CannotAppraiseWrapper>
      )

    case 'errorInvalidRef':
      return (
        <CannotAppraiseWrapper>
          <ScrollToTop />
          <div className='sorryCannotAppraise'>
            <StepHeader
              mainHead={errorCopy[errorLang].header}
              maxWidth='680px'
              subHead={
                lang === 'en_us'
                  ? errorCopy[errorLang].subtext
                  : 'We cannot display this page at the moment.'
              }
            />

            <div className='tradein-buttons-column errorInvalidRef'>
              <TradeinButton
                text='Go to Ford.com'
                type='USFordBackSecondry'
                iconType='prevIcon'
                action={() => goBack(true)}
                invalidRef
              />
            </div>
          </div>
        </CannotAppraiseWrapper>
      )
    case 'vehicleNotFound':
      return (
        <div className='tradein-error-page'>
          <StepHeader mainHead='WE’RE SORRY!' maxWidth='680px' />
          <div className='tradein-subtext'>
            We couldn’t find a vehicle that matches the information
          </div>
          <div className='tradein-buttons-column'>
            <TradeinButton
              type='forward'
              text='Back to Cart'
              action={() => resetApp(true)}
            />
          </div>
        </div>
      )
    case 'error3':
      return (
        <div className='tradein-error-page'>
          <TradeinLink text='Send to Dealer' />
          <TradeinLink text='Appraise Different Vehicle' />
        </div>
      )
    case 'success':
      return <div className='tradein-success-page' />
    case 'vehiclenotfound_EU':
      return (
        <VehicleNotFoundEUWrapper>
          <div className='vehicleNotFound'>
            <h3 className='tradein-title'>
              {vehicleNotFoundCopy[lang].header}
            </h3>
            <p className='tradein-subtitle'>
              {vehicleNotFoundCopy[lang].subtext}
            </p>

            <div className='tradein-vehicle-info'>
              <div className='tradein-vehicle-info-row'>
                <span className='label'>
                  {vehicleNotFoundCopy[lang].regLabel}
                </span>
                <span className='value'>{dataState.registrationNumber}</span>
              </div>
              <div className='tradein-vehicle-info-row'>
                <span className='label'>
                  {vehicleNotFoundCopy[lang].mileageLabel}
                </span>
                <span className='value'>
                  {market === 'GB'
                    ? filters.numberCommas(dataState.mileage)
                    : filters.numberCommasEU(dataState.mileage)}
                </span>
              </div>
            </div>

            <p className='tradein-subtitle'>{vehicleNotFoundCopy[lang].desc}</p>
            <div className='tradein-buttons-container'>
              <TradeinButton
                type='USFordBack'
                text={vehicleNotFoundCopy[lang].button}
                ariaLabel={vehicleNotFoundCopy[lang].buttonAriaLabel}
                action={() => resetApp(true)}
              />
              <TradeinButton
                type='USFordPrimary'
                text={vehicleNotFoundCopy[lang].buttonTryAgain || 'Try Again'}
                ariaLabel={vehicleNotFoundCopy[lang].buttonAriaLabel}
                action={() => goBack('eu')}
              />
            </div>
          </div>
        </VehicleNotFoundEUWrapper>
      )
    default:
      return (
        <div className='tradein-error-page'>
          <TradeinLink text='Try Another Vin' />
          <TradeinLink text='Contact Your Dealer' />
        </div>
      )
  }
}

export function AreYouSureModal({ exit, isDecline }) {
  return <Message type='areYouSure' exit={exit} isDecline={isDecline} />
}
export function AreYouSureModalHeader({ exit }) {
  return <Message type='areYouSureHeader' exit={exit} />
}
export function SorryCannotAppraise({ prevScreen }) {
  return <Message type='sorryCannotAppraise' prevScreen={prevScreen} />
}
export function SorryCannotAppraiseFordUS({ prevScreen }) {
  return <Message type='sorryCannotAppraiseFordUS' prevScreen={prevScreen} />
}
export function SorryCannotAppraiseFusionRecall({ prevScreen }) {
  return (
    <Message type='sorryCannotAppraiseFusionRecall' prevScreen={prevScreen} />
  )
}

export function ErrorMissingRef({ prevScreen }) {
  return <Message type='errorMissingRef' prevScreen={prevScreen} />
}
export function ErrorInvalidRef({ prevScreen }) {
  return <Message type='errorInvalidRef' prevScreen={prevScreen} />
}
export function VehicleNotFound({ prevScreen }) {
  return <Message type='vehicleNotFound' prevScreen={prevScreen} />
}
export function ErrorPage3({ prevScreen }) {
  return <Message type='error3' prevScreen={prevScreen} />
}
export function SuccessPage({ prevScreen }) {
  return <Message type='success' prevScreen={prevScreen} />
}
export function VehicleNotFoundEU({ prevScreen }) {
  return <Message type='vehiclenotfound_EU' prevScreen={prevScreen} />
}
