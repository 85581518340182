import React, { useContext, useState, useEffect } from 'react'
import { navigate } from 'hookrouter'

import { AppContext, AppACTIONS } from '@common/context/AppContext'
import { DataContext, DataACTIONS } from '@common/context/DataContext'
import focusError from '@utils/focusError'
import TextInput from '@common/pageElements/TextInput'
import SelectInput from '@common/pageElements/SelectInput'
import { useInput } from '@common/customhooks/forms'
import TradeinButton from '@common/pageElements/TradeinButton'
import TradeinLink from '@common/pageElements/TradeinLink'

import StepHeader from '@common/pageElements/StepHeader'
import { StepHeaders, ErrorMessage, StepBody } from './Copy'
import api from '@utils/api'
import * as filters from '@utils/filters'
import ScrollToTop from '@common/pageElements/ScrollToTop'
import Tooltip from '@common/pageElements/Tooltip'
import Image from '@common/icons/Image'
import { financeLenders, leaseLenders } from '../../../constants/forms'
import { nonAlphanumericDash } from '@utils/regex'
import { pageLoadTags } from '@utils/analytics/index'
import { footerPrivacy } from '@common/FooterCopy'

import styled from 'styled-components'

import { mediaQueries } from '../../styled-components/mediaQueries'

const StyledFinancing = styled.div`
  .financing {
    max-width: 740px;
    margin: auto;
    &.ford {
      .tradein-input-wrapper {
        max-width: 430px;
      }
      .tradein-links-wrapper {
        border: none;
        @media ${mediaQueries.underM} {
          font-size: var(--fz-md);
        }
        padding: 30px 0px 15px;
      }
      .tradein-divider ~ .tradein-note {
        margin-top: 40px;
        text-align: left;
        max-width: 432px;
        margin: auto;
        color: var(--fordBlack);
        line-height: 16px;
        font-size: 10px;
        font-style: none;
      }
      .tradein-button-ford-wrapper {
        max-width: 656px;
        margin-top: 40px;
      }
      .tradein-fully-secured {
        max-width: 656px;
        margin: auto;
        margin-top: 40px;
        p {
          font-size: var(--fz-xxs);
          line-height: 16px;
          color: var(--fordBlack);
        }
        @media ${mediaQueries.underL} {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      .tradein-links-wrapper a {
        text-decoration: none;
        color: var(--fordAccentBlue);
      }
      .tradein-note {
        font-style: normal;
        letter-spacing: 0px;
        color: var(--fordSlate);
        line-height: 1.5;
      }
      .tradein-tooltip-button {
        margin-left: -7px;
      }
      .secondary-link-text {
        font-weight: 700;
        font-size: var(--fz-sm);
      }
      a {
        display: inline-block;
        text-decoration: none;
        color: var(--fordAccentBlue);
      }
    }

    .tradein-input-wrapper {
      max-width: 397px;
      margin: auto;
      @media ${mediaQueries.underM} {
        width: 320px;
      }
      .tradein-subtext {
        font-family: var(--fontPrimary);
        font-size: var(--fz-xxs);
        max-width: 740px;
        line-height: 1.91;
      }
      svg {
        margin: 0px 0px 2px 3px;
      }
    }
    .tradein-links-wrapper {
      font-size: var(--fz-md);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      text-align: center;
      padding: 30px 0 50px 0;
      flex-direction: column;
      border-bottom: var(--borderInput);
      a {
        margin-top: 10px;
      }
      @media ${mediaQueries.underM} {
        flex-direction: column;
        font-size: var(--fz-xs);
      }
    }
    .tradein-text-input-container {
      max-width: 397px;
      margin: auto;
    }
    .tradein-divider ~ .tradein-note {
      margin-top: 40px;
      text-align: center;
    }
    .tradein-fully-secured {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 4% -25% 0 -25%;
      /* color: var( --fz-xs); */
      font-size: var(--fz-xs);
      @media ${mediaQueries.underL} {
        margin: 4% 0;
        flex-wrap: wrap;
        svg {
          margin: 30px 0;
        }
      }
      svg {
        display: inline-block;
        max-width: 150px;
        width: 100%;
        margin-right: 25px;
      }
    }
    .error-container {
      margin-top: 18px;
    }
    .tradein-subtext {
      font-size: var(--fz-xxs);
      text-align: center;
      margin: -10px 0 35px 0px;
    }
    .tradein-enter-manually {
      max-width: 430px;
      margin: auto;
    }
    .note {
      font-family: var(--fontPrimary);
      font-size: var(--fz-xxs);
      font-style: italic;
    }
    .tradein-note-container {
      margin-top: 40px;
      text-align: center;
    }
    .tradein-lease-link-button-container {
      display: flex;
      justify-content: center;
    }
    &.lincoln {
      .tradein-subtext {
        font-size: var(--fz-lg);
        line-height: 1.44;
        letter-spacing: normal;
      }
    }
  }
`

const StyledFinanceLeaseButtons = styled.div`
  max-width: 380px;
  width: 100%;
`

const StyledFinanceLeaseNext = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 0px auto 0px;
  max-width: 380px;
  width: 100%;

  @media ${mediaQueries.underM} {
    flex-direction: column-reverse;
    max-width: none;
    width: fit-content;
  }
`

const StyledFinancingLeasePrivacy = styled.div`
  text-align: center;
  text-decoration: none;
`
const Financing = () => {
  const { dataState, dataDispatch } = useContext(DataContext)
  const { appState, appDispatch, language, brand, referralId, market } =
    useContext(AppContext)
  const [enterManually, setManual] = useState(
    dataState.enterFinanceManually ||
      dataState.enteredBalance ||
      dataState.enteredObligation ||
      false
  )
  const [notFoundError, setError] = useState(false)
  const lang = footerPrivacy.hasOwnProperty(language) ? language : 'en_us'
  const linkBrand = footerPrivacy[lang].hasOwnProperty(brand) ? brand : 'ford'
  const financeSourcesObj =
    appState.financeType === 'Finance' ? financeLenders : leaseLenders

  if (brand === 'lincoln' && financeSourcesObj[0].name !== 'Lincoln AFS') {
    const lincolnLender = financeSourcesObj[1]
    financeSourcesObj[1] = financeSourcesObj[0]
    financeSourcesObj[0] = lincolnLender
  }

  const isFinance = appState.financeType === 'Finance'
  const [isloaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!isloaded) {
      setLoaded(true)
      appState.financeType === 'Finance'
        ? pageLoadTags.enterFinanceInfo()
        : pageLoadTags.enterLeaseInfo()
    }
    if (appState.visitedSteps.indexOf('financing') === -1) {
      appDispatch({
        type: AppACTIONS.visitedStepsUpdated,
        payload: 'financing'
      })
    }
  }, [appState, appDispatch])

  function handleManual() {
    appState.financeType === 'Finance'
      ? pageLoadTags.enterFinanceInfoManual()
      : pageLoadTags.enterLeaseInfoManual()
    setManual(!enterManually)
  }

  const { financeSource, appraisalId } = dataState
  const source = useInput({
    initialValue: financeSource
      ? { value: financeSource.value, label: financeSource.name }
      : '',
    label: 'Finance Source'
  })
  const acct = useInput({
    // don't display loanAccount number
    initialValue: '',
    label: isFinance ? 'Loan Account Number' : 'Lease Account Number',
    invalidCharacters: nonAlphanumericDash,
    maxLength: {
      value: 32,
      errorMessage: 'Please enter a valid Account Number'
    }
  })
  const finalBalance = dataState.enteredBalance || dataState.enteredObligation
  dataState.actualRemainingBalance || dataState.remainingBalance

  const remainingBalInput = useInput({
    initialValue:
      finalBalance && enterManually ? filters.currencyUSD(finalBalance) : '',
    label: isFinance ? 'Remaining Balance' : 'Amount',
    filter: filters.inputUSD,
    minLength: {
      value: 2
    }
  })

  function saveData(saveAcct, saveSource) {
    dataDispatch({
      type: DataACTIONS.loanAccountUpdated,
      payload: saveAcct
    })
    dataDispatch({
      type: DataACTIONS.financeSourceUpdated,
      payload: saveSource
    })
  }
  const pageValid =
    enterManually === false
      ? financeSource !== 'N/A'
        ? true
        : source.valid && acct.valid
      : remainingBalInput.value

  function handleSubmit() {
    if (!enterManually) {
      const sourceValid = financeSource !== 'N/A' ? true : source.valid
      const acctValid = acct.valid && sourceValid
      if (acctValid) {
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: true
        })
        if (isFinance) {
          callFinanceApi()
        } else {
          callLeaseApi()
        }
      } else {
        focusError([source, acct])
      }
    } else {
      if (remainingBalInput.value && remainingBalInput.value !== '$') {
        // remove formating and convert to number
        const numberBal = filters.deformatCurrency(
          remainingBalInput.value,
          'US'
        )

        // remove loanAccount & financeSource values
        saveData('N/A', 'N/A')
        // set enter manually true so we can return to this state
        dataDispatch({
          type: DataACTIONS.enterFinanceManuallyUpdated,
          payload: true
        })
        dataDispatch({
          type: DataACTIONS.actualRemainingBalanceUpdated,
          payload: 0
        })
        dataDispatch({
          type: DataACTIONS.remainingBalanceUpdated,
          payload: numberBal
        })
        // because this was entered manually, we also need to save it
        // under enteredBalance
        dataDispatch({
          type: isFinance
            ? DataACTIONS.enteredBalanceUpdated
            : DataACTIONS.enteredObligationUpdated,
          payload: numberBal
        })

        if (appState.completedSteps.indexOf('financing') === -1) {
          appDispatch({
            type: AppACTIONS.completedStepsUpdated,
            payload: 'financing'
          })
        }
        appDispatch({
          type: AppACTIONS.jumpToStepUpdated,
          payload: 'confirmbalance'
        })
        navigate('/confirmbalance')
      } else {
        focusError([remainingBalInput])
      }
    }
  }

  function callFinanceApi() {
    api
      .sendFinancing({
        source: source.value.value,
        accountNumber: acct.value,
        appraisalId: appraisalId,
        referralId
      })
      .then((data) => {
        saveData(acct.value, source.value)
        // update manually entered balance to null
        dataDispatch({
          type: DataACTIONS.enteredBalanceUpdated,
          payload: null
        })
        dataDispatch({
          type: DataACTIONS.actualRemainingBalanceUpdated,
          payload: 0
        })
        dataDispatch({
          type: DataACTIONS.enterFinanceManuallyUpdated,
          payload: false
        })
        dataDispatch({
          type: DataACTIONS.remainingBalanceUpdated,
          payload: data.remainingBalance
        })
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })

        if (appState.completedSteps.indexOf('financing') === -1) {
          appDispatch({
            type: AppACTIONS.completedStepsUpdated,
            payload: 'financing'
          })
        }
        appDispatch({
          type: AppACTIONS.jumpToStepUpdated,
          payload: 'confirmbalance'
        })
        if (data.ownershipType === 'LEASE') {
          appDispatch({
            type: AppACTIONS.financeTypeUpdated,
            payload: 'Lease'
          })
          if (
            source.value.name !== 'Ford Credit' &&
            source.value.name !== 'Lincoln AFS'
          ) {
            dataDispatch({
              type: DataACTIONS.remainingBalanceUpdated,
              payload: data.totalOutstandingBalanceAmount
            })
          } else {
            dataDispatch({
              type: DataACTIONS.remainingBalanceNoFeesUpdated,
              payload: data.outstandingBalanceAmountWithoutFees
            })
            dataDispatch({
              type: DataACTIONS.remainingBalanceUpdated,
              payload: data.totalOutstandingBalanceAmount
            })
            dataDispatch({
              type: DataACTIONS.otherFeesUpdated,
              payload: data.otherFeesBalance
            })
            dataDispatch({
              type: DataACTIONS.lateFeesUpdated,
              payload: data.lateChargeOutstandingAmount
            })
          }
        }

        navigate('/confirmbalance')
      })
      .catch((error) => {
        console.log(error)
        setError(true)
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
      })
  }

  function callLeaseApi() {
    api
      .sendFinancing({
        source: source.value.value,
        accountNumber: acct.value,
        appraisalId: appraisalId,
        referralId
      })
      .then((data) => {
        // update manually entered balance to null
        dataDispatch({
          type: DataACTIONS.enteredObligationUpdated,
          payload: null
        })
        dataDispatch({
          type: DataACTIONS.actualRemainingBalanceUpdated,
          payload: 0
        })
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
        saveData(acct.value, source.value)
        dataDispatch({
          type: DataACTIONS.enterFinanceManuallyUpdated,
          payload: false
        })
        if (
          source.value.name !== 'Ford Credit' &&
          source.value.name !== 'Lincoln AFS'
        ) {
          dataDispatch({
            type: DataACTIONS.remainingBalanceUpdated,
            payload: data.totalOutstandingBalanceAmount
          })
        } else {
          dataDispatch({
            type: DataACTIONS.remainingBalanceNoFeesUpdated,
            payload: data.outstandingBalanceAmountWithoutFees
          })
          dataDispatch({
            type: DataACTIONS.remainingBalanceUpdated,
            payload: data.totalOutstandingBalanceAmount
          })
          dataDispatch({
            type: DataACTIONS.otherFeesUpdated,
            payload: data.otherFeesBalance
          })
          dataDispatch({
            type: DataACTIONS.lateFeesUpdated,
            payload: data.lateChargeOutstandingAmount
          })
        }
        if (appState.completedSteps.indexOf('financing') === -1) {
          appDispatch({
            type: AppACTIONS.completedStepsUpdated,
            payload: 'financing'
          })
        }

        appDispatch({
          type: AppACTIONS.jumpToStepUpdated,
          payload: 'confirmbalance'
        })
        navigate('/confirmbalance')
      })
      .catch((error) => {
        console.log(error)
        setError(true)
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
      })
  }

  return (
    <StyledFinancing>
      <ScrollToTop />
      <div className={`financing ${brand} ${market}`}>
        <StepHeader
          mainHead={StepHeaders.financing[appState.financeType][brand].main}
          subHead={StepHeaders.financing[appState.financeType][brand].sub}
        />
        {!enterManually ? (
          <div>
            <div className='tradein-input-wrapper'>
              {/* <h2 className='tradein-subtext'>
                {StepHeaders.financing[appState.financeType].sub}
              </h2> */}
              <div className='tradein-text-input-container'>
                <SelectInput {...source} list={financeSourcesObj} />
                <TextInput {...acct} class='no-mouseflow' />
                {notFoundError && (
                  <span className='tradein-error-message'>
                    {ErrorMessage.financing[appState.financeType].errorMessage}
                  </span>
                )}
                {brand === 'ford' ? (
                  <Image img='fordSecure' />
                ) : (
                  <Image img='secured' />
                )}
              </div>
              <div className='tradein-links-wrapper'>
                <div>Not seeing your lender?</div>
                {brand === 'ford' ? (
                  <TradeinLink
                    type='fordSecondary'
                    text={
                      StepBody.financing[appState.financeType][brand].linkText
                    }
                    action={handleManual}
                    icon='nextIcon'
                  />
                ) : (
                  <TradeinLink
                    type='secondary'
                    text={
                      StepBody.financing[appState.financeType][brand].linkText
                    }
                    action={handleManual}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className='tradein-enter-manually'>
            <h2 className='tradein-subtext'>
              {StepHeaders.financing[appState.financeType][brand].manuallySub}
            </h2>
            <div className='tradein-text-input-container'>
              <TextInput {...remainingBalInput} />
              <p className='tradein-note'>
                {StepBody.financing[appState.financeType][brand].note}
                {isFinance && (
                  <Tooltip
                    tooltipBody="A manually entered remaining loan balance is not verified 
                                by your lender.  We cannot guarantee your trade-in 
                                vehicle's equity calculation will be entirely accurate.  
                                Your dealer will help verify your loan balance during the 
                                vehicle inspection."
                    id='financeInfoId'
                    className='iball'
                  />
                )}
              </p>
            </div>
            <div className='tradein-links-wrapper'>
              <div>
                {' '}
                {StepBody.financing[appState.financeType][brand].linkText2}
              </div>
              {brand === 'lincoln' ? (
                <TradeinLink
                  type='secondary'
                  text='Enter account information instead'
                  action={handleManual}
                />
              ) : (
                <TradeinLink
                  type='fordSecondary'
                  text='Enter account information'
                  action={handleManual}
                  icon='nextIcon'
                />
              )}
            </div>
          </div>
        )}
        <div className='tradein-divider' />
        <p className='tradein-note'>
          {StepBody.financing[appState.financeType][brand].note2}
        </p>
        <StyledFinancingLeasePrivacy>
          <TradeinLink
            ariaLabel={footerPrivacy[lang].ariaLabel}
            type='primary'
            text={footerPrivacy[lang][linkBrand].text}
            link={footerPrivacy[lang][linkBrand].link}
          />
        </StyledFinancingLeasePrivacy>
        {brand === 'lincoln' ? (
          <div className='tradein-buttons-container'>
            <StyledFinanceLeaseButtons>
              <StyledFinanceLeaseNext>
                {isFinance ? (
                  <TradeinButton type='back' target='vehiclecondition' />
                ) : (
                  <TradeinButton type='back' target='vehiclefound' />
                )}
                <TradeinButton
                  type='primary'
                  text='Next'
                  action={handleSubmit}
                  iconType='nextIcon'
                />
              </StyledFinanceLeaseNext>
              <StyledFinancingLeasePrivacy>
                <TradeinLink
                  ariaLabel={footerPrivacy[lang].ariaLabel}
                  type='primary'
                  text={footerPrivacy[lang][linkBrand].text}
                  link={footerPrivacy[lang][linkBrand].link}
                />
              </StyledFinancingLeasePrivacy>
            </StyledFinanceLeaseButtons>
          </div>
        ) : (
          <div
            className={`tradein-buttons-container tradein-button-ford-wrapper`}
          >
            {isFinance ? (
              <TradeinButton
                type='USFordBack'
                text='Back'
                iconType='prevIcon'
                target='vehiclecondition'
              />
            ) : (
              <TradeinButton
                type='USFordBack'
                text='Back'
                iconType='prevIcon'
                target='vehiclefound'
              />
            )}
            <TradeinButton
              type='USFordPrimary'
              text='Continue'
              iconType='nextIcon'
              disabled={!pageValid}
              action={() => handleSubmit()}
            />
          </div>
        )}
        {!enterManually ||
          (brand === 'ford' && (
            <div className='tradein-fully-secured'>
              <Image img='fullySecured' />
              <p>
                The security and privacy of your information is a serious matter
                that’s not taken lightly. We use standard SSL encryption to
                securely transmit and store all data. The information you’ve
                provided will be shared with your dealer, but third parties will
                not receive it for marketing communications. View our privacy
                policy and terms and conditions for more details.
              </p>
            </div>
          ))}
      </div>
    </StyledFinancing>
  )
}

export default Financing
