import React, { useContext } from 'react'
import { AppContext } from '../context/AppContext'
import { DataContext } from '../context/DataContext'
import { useRoutes, navigate, usePath } from 'hookrouter'

import FusionRecallTnC from '@components/custom/US/fusionRecallTnC'
import TermsAndConditionsUS from '../../custom/US/TermsAndConditions'

import LandingPageUS from '../../custom/US/LandingPage_US'
import VehicleFound from '../../custom/US/VehicleFound'
import SelectFinanceMethod from '../../custom/US/SelectFinanceMethod'

import VehicleBasics from '../../custom/US/VehicleBasics'
import VehicleCondition from '../../custom/US/VehicleCondition'
import ConfirmBalance from '../../custom/US/ConfirmBalance'

import {
  SorryCannotAppraise,
  SorryCannotAppraiseFordUS,
  SorryCannotAppraiseFusionRecall,
  VehicleNotFound,
  ErrorMissingRef,
  ErrorInvalidRef
} from '../messages/Messages'
import Health from '../messages/Health'

import TradeInAppraisal from '../../custom/US/TradeInAppraisal'

import Financing from '../../custom/US/Financing'
import LoadingSpinner from '../pageElements/LoadingSpinner'

const StepContent = () => {
  const { dataState } = useContext(DataContext)
  const { market, referralId, application } = useContext(AppContext)

  // check application for DE
  const routes = {
    '/': () => {
      if (market) {
        if (
          market === 'US' &&
          !dataState.vin &&
          application !== 'FUSION_RECALL'
        ) {
          return <SelectFinanceMethod />
        } else if (market === 'US' && application === 'FUSION_RECALL') {
          return <LandingPageUS />
        } else if (market === 'US' && dataState.vin) {
          return <VehicleFound />
        }
      } else {
        return <LoadingSpinner showLoadingSpinner />
      }
    },
    '/ownershiptype': () => <SelectFinanceMethod />,

    // US own path
    '/landingpage_US': () => <LandingPageUS />,
    '/vehicleBasics': () => <VehicleBasics />,
    '/vehiclefound': () => <VehicleFound />,
    '/vehiclebasics': () => <VehicleBasics />,
    // '/vehiclefeatures': () => <VehicleFeatures />,
    '/vehiclecondition': () => <VehicleCondition />,
    '/tradeinappraisal': () => <TradeInAppraisal />,
    '/termsandconditions': () => <VehicleCondition />,

    // finance || leased
    '/financing': () => <Financing />,
    '/confirmbalance': () => <ConfirmBalance />,

    '/termsandconditionsUS': () => <TermsAndConditionsUS />,
    '/fusionrecalltnc': () => <FusionRecallTnC />,

    // error
    '/sorryCannotAppraiseFordUS': () => <SorryCannotAppraiseFordUS />,
    '/sorryCannotAppraiseFusionRecall': () => (
      <SorryCannotAppraiseFusionRecall />
    ),

    '/sorryCannotAppraise': () => <SorryCannotAppraise />,
    '/vehicleNotFound': () => <VehicleNotFound />,
    '/errorMissingRef': () => <ErrorMissingRef />,
    '/errorInvalidRef': () => <ErrorInvalidRef />,

    '/health': () => <Health />
  }

  const routeResult = useRoutes(routes)
  const currentPath = usePath()

  const renderRoute = () => {
    const siteReferrer = document.referrer
    if (
      !referralId &&
      currentPath !== '/health' &&
      !currentPath.includes('termsandconditions') &&
      !currentPath.includes('fusionrecalltnc')
    ) {
      if (siteReferrer.includes('ford.com')) {
        navigate('/errorMissingRef')
        return <ErrorMissingRef />
      } else {
        navigate('/errorInvalidRef')
        return <ErrorInvalidRef />
      }
    }
    return routeResult
  }

  return (
    <div>
      <LoadingSpinner showLoadingSpinner={dataState.showLoadingSpinner} />
      {renderRoute()}
    </div>
  )
}
export default StepContent
